import { useEffect } from 'react';
import { getValueFromQueryString } from 'hooks/useSearchParams';
import { useSelector } from 'react-redux';

const key = 'referrerId';

// Get & set referral id into localStorage
export const getReferrerId = () => {
  const defaultReferrerId = +localStorage.getItem(key);
  const referrerId = +getValueFromQueryString(key);

  if (referrerId && defaultReferrerId !== referrerId) {
    localStorage.setItem(key, referrerId);
    return referrerId;
  }

  return defaultReferrerId;
};

export const removeReferrerId = () => {
  localStorage.removeItem(key);
  console.log(`Referrer id has been removed`);
};

// Use referral identity hook
export const useReferralId = () => {
  useEffect(() => { getReferrerId() }, []);
};

export const useReferral = () => {
  const customer = useSelector(({ dashboard }) => dashboard.profile.customer);
  // const siteUrl = useSelector(({ global }) => global.siteDomain);
  const dashboardUrl = useSelector(({ global }) => global.dashboardUrl) || window.location.origin;
  const referralId = getReferrerId();

  const referralLink = `${dashboardUrl}/welcome?referrerId=${customer.id}`;
  const discountValue = 30;

  return {
    referralLink,
    referralId,
    discountValue,
  };
};
