import jwtDecode from 'jwt-decode';

export const tokenService = {
  setTokens,

  getAccessToken,
  decodeAccessToken,

  getRefreshToken,
  clearTokens,
};

function setTokens({ accessToken, refreshToken }) {
  console.log('Set tokens. Access', !!accessToken, 'Refresh', !!refreshToken);
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }
  if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
}

function getAccessToken() {
  return localStorage.getItem('accessToken');
}

function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

function decodeAccessToken() {
  const accessToken = getAccessToken();
  if (!accessToken) return; // check exists
  try {
    const decodedToken = jwtDecode(accessToken);
    const { user_claims, identity } = decodedToken;

    // Adapt data
    const result = { ...user_claims, identity };
    return result;
  } catch (error) {
    console.error(error);
  }
  return {};
}

function clearTokens() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}
