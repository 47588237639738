import Fingerprint2 from 'fingerprintjs2';
import { store } from 'index';

// Debounce
const utils = (function () {
    const isDefined = variable => !!typeof variable !== 'undefined';

    return {
        isDefined,
    }
})();

export const tasks = (function () {
    const execTimers = {};

    const add = ({ name, timeout, task }) => {

        if (utils.isDefined(execTimers[name])) {
            clearTimeout(execTimers[name]);
        }

        // Start new task after timeout
        execTimers[name] = setTimeout(() => {
            task();
            delete execTimers[name];

        }, timeout);
    }

    return {
        add
    }
})();

/**
 * Check if object is empty
 * @param {object} obj
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }

    return true;
}

/**
 * Check if element is in viewport
 * @param {documentElement} elem - DOM element
 * @returns {boolean}
 */
export function isInViewport(elem) {
    const bounding = elem && elem.getBoundingClientRect();

    if (!bounding) return;

    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

/**
 * @typedef {Object} MutationObserverMethods
 * @property {function} observe
 * @property {function} unobserve
 */

/**
 * Create mutation observer
 * @function createMutationObserver
 * @param {documentElement} targetNode
 * @returns {MutationObserverMethods}
 */
export const createMutationObserver = function (targetNode) {
    const config = { attributes: true, childList: true, subtree: true, characterData: true };

    const callback = function (mutationsList, observer) {
        for (let mutation of mutationsList) {
            if (mutation.type === 'characterData') {
                targetNode.classList.add('flush');

                setTimeout(() => {
                    targetNode.classList.remove('flush');
                }, 250)
            }
        }
    };

    const observer = new MutationObserver(callback);

    const observe = function () {
        observer.observe(targetNode, config);
    }

    const unobserve = function () {
        observer.disconnect();
    }

    return {
        observe,
        unobserve
    }
}

/**
 * Create mutation observer
 * @function createIntersectionObserver
 * @param {documentElement} target
 * @returns {IntersectionObserverMethods}
 */
export const createIntersectionObserver = function (container, target, cb) {
    const options = {
        root: container,
        rootMargin: '0px',
        threshold: 0
    }

    const intersect = (entries, observer) => {
        entries.forEach(item => {
            if (item.isIntersecting) {
                cb && cb(item.target, observer);
            }
        })
    }

    const observer = new IntersectionObserver(intersect, options);

    const observe = function () {
        // Check for nodeList
        if (Object.prototype.isPrototypeOf.call(NodeList.prototype, target)) {
            target.forEach(item => observer.observe(item));

        } else {
            observer.observe(target);
        }
    }

    const unobserve = function () {
        // Check for nodeList
        if (Object.prototype.isPrototypeOf.call(NodeList.prototype, target)) {
            target.forEach(item => observer.unobserve(item));

        } else {
            observer.unobserve(target);
        }
    }

    const addObservable = (target) => {
        observer.observe(target);
    }

    const removeObservable = (target) => {
        observer.unobserve(target);
    }

    return {
        observe,
        unobserve,
        addObservable,
        removeObservable
    }
}

/**
 * @typedef {Object} InfoByStatus
 * @property {string} cardClass
 * @property {string} headerEmoji
 */

/**
 * Get unique info by order status id
 * @param {number} status
 * @returns {InfoByStatus}
 */
export function getInfoByOrderStatus(status) {
    const initialStatus = {
        cardClass: '',
        headerEmoji: ''
    }

    switch (status) {
        case 1:
            return {
                ...initialStatus,
                cardClass: 'unpaid',
                headerEmoji: ':('
            }

        case 2:
            return {
                ...initialStatus,
                cardClass: 'paid'
            }

        case 3:
            return {
                ...initialStatus,
                cardClass: 'in-progress'
            }

        case 5:
            return {
                ...initialStatus,
                cardClass: 'completed',
                headerEmoji: ':)'
            }

        case 7:
            return {
                ...initialStatus,
                cardClass: 'refunded'
            }

        case 8:
            return {
                ...initialStatus,
                cardClass: 'canceled'
            }

        case 9:
            return {
                ...initialStatus,
                cardClass: 'hold'
            }

        case 10:
            return {
                ...initialStatus,
                cardClass: 'modified'
            }

        case 11:
            return {
                ...initialStatus,
                cardClass: 'offered'
            }

        default:
            return initialStatus;
    }
}

/**
 * Get unique info by revision status id
 * @param {number} status
 * @returns {InfoByStatus}
 */
export function getInfoByRevisionStatus(status) {
    const initialStatus = {
        cardClass: '',
        headerEmoji: ''
    }

    switch (status) {
        case 1:
            return {
                ...initialStatus,
                cardClass: 'requested',
            }

        case 2:
            return {
                ...initialStatus,
                cardClass: 'in-progress',
            }
        case 3:
            return {
                ...initialStatus,
                cardClass: 'disputed',
            }

        case 4:
            return {
                ...initialStatus,
                cardClass: 'rejected',
            }

        case 5:
            return {
                ...initialStatus,
                cardClass: 'completed',
                headerEmoji: '!'
            }

        case 6:
            return {
                ...initialStatus,
                cardClass: 'canceled'
            }

        case 7:
            return {
                ...initialStatus,
                cardClass: 'closed'
            }

        case 8:
            return {
                ...initialStatus,
                cardClass: 'failed'
            }

        case 9:
            return {
                ...initialStatus,
                cardClass: 'graded'
            }

        case 10:
            return {
                ...initialStatus,
                cardClass: 'expired'
            }

        case 11:
            return {
                ...initialStatus,
                cardClass: 'undelivered'
            }

        case 12:
            return {
                ...initialStatus,
                cardClass: 'verifying'
            }

        default:
            return initialStatus;
    }
}

/** Smooth scroll to Top */
export function smoothScrollTop() {
    window.scrollTo(0, 0);
    // const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

    // if (currentScroll > 0) {
    //     window.requestAnimationFrame(smoothScrollTop);
    //     window.scrollTo(0, currentScroll - (currentScroll / 5));
    // }
};

/**
 * Copy text
 * @param {Element} refElement
 * @param {function} notify
 */
export function copyText(refElement, notify) {
    const text = refElement.current;
    let range;
    let selection;

    if (document.body.createTextRange) {

        range = document.body.createTextRange();
        range.moveToElement(text);
        range.select();

    } else if (window.getSelection) {

        selection = window.getSelection();

        range = document.createRange();
        range.selectNodeContents(text);

        selection.removeAllRanges();
        selection.addRange(range);

    }

    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    notify && notify.info('Copied to clipboard!');
}

/**
 * Get formated date
 * @param {Date} date
 * @param {object} options
 * @returns {string} date
 */
export function getFormatedDate(date, options) {
    const defaultOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: '2-digit',
        hourCycle: 'h23'
    };

    const config = (typeof options === 'object' && options !== null) ? options : defaultOptions;
    let formatedDate;

    if (Object.prototype.toString.call(date) === '[object Date]') {
        formatedDate = date;

    } else {
        formatedDate = date?.toString().replace(/-/g, '/') + ' UTC'; // Format date for safari
    }

    if (isFinite(new Date(formatedDate))) {
        return new Intl.DateTimeFormat('en-US', config).format(new Date(formatedDate));

    } else {
        console.log('Invalid date', date);
        return '';
    }
}

export const isBrowser = {
    Opera: function () {
        return (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    },
    Firefox: function () {
        return typeof InstallTrigger !== 'undefined';
    },
    Safari: function () {
        return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window.safari.pushNotification));
    },
    MobileSafari: function () {
        return /iP(ad|hone|od).+Version\/[\d.]+.*Safari/i.test(navigator.userAgent);
    },
    IE: function () {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        return (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./));
    },
    Edge: function () {
        return navigator.userAgent.indexOf('Edge') >= 0;
    },
    Chrome: function () {
        return !!window.chrome && /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    },
    MS: function () {
        return isBrowser.IE() || isBrowser.Edge();
    },
    // Detect bot
    Bot: function () {
        return ((typeof window !== "undefined") && !("onscroll" in window)) || /(gle|ing|ro)bot|crawl|spider/i.test(navigator.userAgent)
    }
};

/**
 * Check for browser
 * @returns {string} browser name
 */
export function checkBrowser() {
    let browser;

    switch (true) {
        case isBrowser.MS():
            browser = 'Internet Explorer'
            break;
        case isBrowser.IE():
            browser = 'Internet Explorer'
            break;
        case isBrowser.Firefox():
            browser = 'Mzilla Firefox'
            break;
        case isBrowser.Edge():
            browser = 'Edge'
            break;
        case isBrowser.Chrome():
            browser = 'Chrome'
            break;
        case isBrowser.Opera():
            browser = 'Opera'
            break;
        case isBrowser.Safari():
            browser = 'Safari'
            break;
        case isBrowser.MobileSafari():
            browser = 'Safari Mobile'
            break;
        default:
            browser = '-'
            break;
    }

    return browser;
}

/**
 * Choose payment system
 * @param {string} language
 * @param {number} siteCategory
 * @param {number} workType
 * @param {object} paymentRules
 */
export function getPaymentSystems(language = 'en', siteCategory = 1, workType = 1, paymentRules) {
    const lang = language.toLowerCase();
    const rules = paymentRules[lang];
    const currentRule = rules.filter(rule => siteCategory === rule.siteCategory);
    const paymentSystems = currentRule[0].rules.filter(item => +workType === item.workTypeId);

    return paymentSystems[0].paymentSystems;
}

/**
 * Get fingerprint hash
 * @returns {string} hash
 */
export function getFingerprint() {
    // const options = {
    //     excludes: { doNotTrack: true }
    // }

    // // With options
    // return Fingerprint2.getPromise(options)
    //     .then((components) => {
    //         const values = components.map(function(component) { return component.value });
    //         const hash = Fingerprint2.x64hash128(values.join(''), 31);

    //         return hash;
    //     })
    //     .catch(err => console.error('Fingerprint2: ', err))

    return Fingerprint2.getPromise()
        .then((components) => {
            const values = components.map(function (component) { return component.value });
            // const hashObj = components.map(function(component) {
            //     return component
            // });
            // console.log('hashValues', hashObj);

            const hash = Fingerprint2.x64hash128(values.join(''), 31);

            return hash;
        })
        .catch(err => console.error('Fingerprint2: ', err))
}

/**
 * Append script tag with given source
 * @param {string} url
 * @param {function} callback
 * @param {boolean} async
 * @param {boolean} defer
 */
export function includeScripts(url, callback, async = true, defer = true) {
    if (url) {
        const script = document.createElement('script');

        script.src = url;
        script.async = async;
        script.defer = defer;
        script.setAttribute('data-cfasync', false);

        document.body.appendChild(script);

        callback && callback();
    }
}

/**
 *
 * @param {number} directionId
 */
export function getMessageSender(directionId) {
    switch (true) {
        case [1, 2].includes(directionId):
            return 'customer';
        case directionId === 3:
            return 'writer';
        case directionId === 6:
            return 'support';
        default:
            break;
    }
}

// Crutch for backward compatibility
export const isNextGenSite = () => {
  const { global } = store.getState()
  if (!global) {
    return console.error('#isNextGenSite error. Failed get store state');
  }
  const { siteId } = global;
  return siteId >= 80 // !ids of nextgen sites
};

export const upperFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const hexToRgb = (hex) => {
  let chanels = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return (chanels ? [
     parseInt(chanels[1], 16),
     parseInt(chanels[2], 16),
     parseInt(chanels[3], 16),
   ] : [0,0,0,1]).join(',')
}

export const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const entries = urlParams.entries();
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};
