// ! IN PROGRESS...
import http from 'services/http';
import { notify } from 'helpers/notification';
import { useSelector } from 'react-redux';
import { tokenService } from 'services/tokenService';
import { siteStatuses } from 'const';

export function getUTM(term = '', campaign = 'studycrumb') {
  return `utm_source=${window.location.host}&utm_medium=cpc&utm_campaign=${campaign}&utm_term=${term}`;
}

export async function checkSiteTransfer(response) {
  const { siteTransfer } = response;

  if (!siteTransfer) return; // stop! continue...
  const { site, appDashboard, magicLink } = siteTransfer; // date UTC
  console.log(`🪄🔗 Magic-link`, magicLink);

  // Show message before redirect
  const baseUrl = `https://${appDashboard || site}`;
  const utm = getUTM(magicLink ? 'magicLink' : 'login');
  const { pathname } = window.location;
  const href = `${baseUrl}${magicLink ? `${pathname}?magicLink=${magicLink}&` : `/login?`}${utm}`;

  notify.config({ maxCount: 3, duration: 3 });
  await notify.success(`All your data has been transferred to ${site}.
    Come visit our shiny new website to get the most of our services.`);
  await notify.wait(`You will be redirected to ${site}`);

  // Logout
  http.setAccessToken();
  tokenService.clearTokens();

  // Redirect
  window.location.href = href;

  return true; // site is transfer(ing\ed)
}

export function useSiteTransfer() {
  const siteTransfer = useSelector(({ global }) => global.siteTransfer);
  const siteStatus = useSelector(({ global }) => global.siteStatus);

  const { site, appDashboard, bulk, dateStarted } = siteTransfer;
  const targetUrl = `https://${appDashboard || site}`;

  const isAlreadyTransferred = siteStatus.id === siteStatuses.transferred;
  const isDateStarted = new Date(dateStarted?.replace(/-/g, "/") + ' UTC') - new Date() <= 0;
  const isManualTransfer = siteStatus.id === siteStatuses.pending && isDateStarted && !bulk;

  return {
    dateStarted, // UTC
    site,
    appDashboard, // dashboard URL
    bulk, // bulk transfer flag
    targetUrl, // with https://
    status: { ...siteStatus }, // {id, name}

    isManualTransfer, // flag
    isAlreadyTransferred,
  };
}
