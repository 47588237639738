import React, { lazy, Suspense, useState, useEffect } from 'react';
import WS from 'services/ws';

import { useReferralId } from './hooks/useReferralId';
import { hexToRgb } from 'helpers/utils';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { checkMagicLogin, checkUser } from './store/login/loginActions';
import { fetchDefaultSettings } from './store/orderform/orderformglobal/globalAction';
import { getOrdersCount } from './store/dashboard/orderlist/ordersActions';
import { fetchDiscountRules, stopLoyaltyFetching } from './store/dashboard/loyalty/loyaltyActions';
import { fetchDiscountList } from './store/dashboard/loyalty/loyaltyActions.js'

// Routing
import { history } from 'helpers/history';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from './shared/PrivateRoute';

// Dashboard
import { MainLayout } from 'dashboard/components/layout/MainLayout';
import { TabLinks } from './dashboard/components/tablinks/TabLinks';
import { OrderinfoRedirect } from './dashboard/OrderinfoRedirect';

// Modals
import Modal from './shared/Modal';
import ErrorModal from './dashboard/ErrorModal';
import PrivacyModal from './dashboard/profile/privacymodal/PrivacyModal';
import PaymentModal from './dashboard/paymentmodal/PaymentModal';
import ForgotPassModal from './orderform/components/forgotpass/ForgotPassModal';
import FeedbackModal from './dashboard/components/rating/FeedbackModal';
import RevisionInfoModal from './dashboard/orderinfo/revisions/InfoModal';
import RevisionCreateModal from './dashboard/orderinfo/revisions/createmodal/RevisionCreateModal';

// Transfer site
import { TransferBanner } from 'transfer/TransferBanner';
import { useSiteTransfer } from 'services/transfer';
import { TransferNotice } from 'transfer/TransferNotice';
import { SpinFullscreen } from 'shared/SpinFullscreen';

// Lazy pages
const Order = lazy(() => import('./pages/Order'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Forgot = lazy(() => import('./pages/Forgot'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Orderinfo = lazy(() => import('./pages/Orderinfo'));
const Loyalty = lazy(() => import('./pages/Loyalty'));
const Profile = lazy(() => import('./pages/Profile'));
const Transactions = lazy(() => import('./pages/Transactions'));
const NotFound = lazy(() => import('./pages/NotFound'));
const ServerError = lazy(() => import('./pages/ServerError'));
const WelcomeReferral = lazy(() => import('./pages/WelcomeReferral'));

const modals = { RevisionInfoModal, RevisionCreateModal, PrivacyModal, PaymentModal, FeedbackModal, ForgotPassModal, ErrorModal };


/**
 * Root App component
 * @component
 * @category App
 * @hideconstructor
 */
function App(props) {
  const [isLoading, setIsLoading] = useState(true);

  // Store
  const dispatch = useDispatch();
  const isAuthorized = useSelector(({ global }) => global.isAuthorized);
  const modalOpened = useSelector(({ global }) => global.modalOpened);
  const modalComponentName = useSelector(({ global }) => global.modalComponent);
  const modalInfo = useSelector(({ global }) => global.modalInfo);
  const mainColor = useSelector(({ global }) => global.mainColor);
  const links = useSelector(({ dashboard }) => dashboard.nav.links);
  const stepEnabled = useSelector(({ global }) => global.orderform.stepEnabled);
  const defaultsFailedError = useSelector(({ global }) => global.defaultsFailedError);

  const { isAlreadyTransferred } = useSiteTransfer();

  // Store referral id in localStorage
  useReferralId();

  // Init app -> check tokens -> authorize user
  useEffect(() => {

    dispatch(fetchDefaultSettings())
      // Check magic login from qs ?magicLink=<string>
      .then(() => dispatch(checkMagicLogin()))

      // Verify tokens & check user
      .then(() => dispatch(checkUser()))

      .finally(() => setIsLoading());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mainColor) return;

    let colors = [
      ['--main-color', mainColor],
      ['--main-color-rgb', hexToRgb(mainColor)],
    ];

    // Update CSS variables
    colors.forEach(([name, value]) => document.documentElement.style.setProperty(name, value));
  }, [mainColor]);

  useEffect(() => {
    if (!isAuthorized) return;

    dispatch(getOrdersCount());

    // Legacy loyalty
    dispatch(fetchDiscountRules()) // dashboard.loyalty points<number> & rules<{title,from,to,percent}>
      .then(() => dispatch(fetchDiscountList()))
      .finally(() => dispatch(stopLoyaltyFetching())); // dashboard.loyalty.isFetching

    WS.connect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  const InnerModalComponent = modals[modalComponentName];

  if (defaultsFailedError) {
    return <ServerError />;
  }

  return (
    <>
      <Router history={history}>
        <MainLayout>
          <main className="main dashboard">
            <div className="container main-wrapper">
              {isAuthorized && (
                <nav className="nav">
                  <TabLinks links={links} baseUrl="" />
                </nav>
              )}

              {isLoading ? <SpinFullscreen/> : (
                <section>
                  <Suspense fallback={<SpinFullscreen/>}>
                    <Switch>
                      <Route exact path="/">
                        {stepEnabled ? <Redirect to="/order/controls" /> : <Redirect to="/order" />}
                      </Route>

                      <Route path="/order">
                        {isAlreadyTransferred ? <TransferBanner/> : <Order/>}
                      </Route>

                      <Route path="/register" component={Register} />
                      <Route path="/login" component={Login} />
                      <Route path="/forgot" component={Forgot} />

                      <PrivateRoute path="/dashboard" component={Dashboard} />
                      <PrivateRoute path="/transactions" component={Transactions} />
                      <PrivateRoute path="/profile" component={Profile} />
                      <PrivateRoute path="/loyalty" component={Loyalty} />
                      <PrivateRoute path="/orderinfo/:id" component={Orderinfo} />
                      <PrivateRoute exact path="/orderinfo" component={OrderinfoRedirect} />

                      {/* [Reworked] Referral system -> Welcome referral page */}
                      <Route exact path="/welcome" component={WelcomeReferral} />

                      <Route path="/404" component={NotFound} />

                      <Route component={NotFound} />
                    </Switch>
                  </Suspense>
                </section>
              )}
            </div>
          </main>
        </MainLayout>

      </Router>

      {modalOpened && (
        <Modal>
          <InnerModalComponent {...modalInfo} />
        </Modal>
      )}

      {/* [new] Site transfer */}
      <TransferNotice />
    </>
  );
}

export default App;
