import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Main styles
import './styles/main.scss';

import App from './App';
import rootReducer from './store/rootReducer';

const initialState = {};
const middleware = [thunk];
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

export const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
);
try {
    ReactDOM.render(
        <Provider store={store}>
            < App />
        </Provider>,
        document.getElementById('root') || document.createElement('div') // for testin purposes
    );
} catch (err) {
    let count = 0;
    const errorInterval = setInterval(() => {
        count++;
        if (window.Sentry) {
            window.Sentry.captureException(`[React] ${err}`);
            console.error(`[React] ${err}`);
            clearInterval(errorInterval);
        }
    }, 200);

    if (count > 5) {
        clearInterval(errorInterval);
    } else {
        console.error(`!Sentry is not available [React] ${err}`);
    }
}
