import {
    USER_AUTHORIZED,
    SIGN_IN_SUCCESS,
    SIGN_IN_FAILURE,
    LOGOUT_SUCCESS,
    LOCK,
    UNLOCK,
    TOGGLE_MODAL,
    START_EDITING_ORDER,
    START_CLONING_ORDER,
    CLEAR_ORDERFORM,
    SET_VIEW,
    SET_VIEW_ON_RESIZE
} from 'store/actionTypes';

import {
    FETCH_DEFAULT_SETTINGS,
    USER_SIGN_UP_SUCCESS,
    SELECT_OPTION,
    FIRST_STEP_VALIDATION,
    SECOND_STEP_VALIDATION,
    THIRD_STEP_VALIDATION,
    SET_DEFAULT_VALUE,
    CREATE_ORDER,
} from 'store/orderform/orderformActionTypes';

import { shouldRender as getConfig } from 'orderform/utils/config';

export const initialState = {
    isAuthorized: false,
    locked: false,
    defaultsAlreadyFetched: false,
    modalOpened: false,
    modalComponent: '',
    modalInfo: {},
    multipayment: false,
    messengerApiURL: '',
    orderMessengerApiURL: '',
    messengerWebsoketURL: '',
    messengerDisabled: false,
    // orderform state
    siteId: null,
    siteCategory: null,
    siteLanguage: 'EN',
    mainColor: '', // [CMS] gVar - application_theme, value - {"mainColor": "green"}
    siteDomain: '',
    supportEmail: '',
    signUpSuccess: false,
    stepEnabled: true,
    firstStepValid: false,
    secondStepValid: false,
    thirdStepValid: false,
    referralID: 0,
    yandexID: '', // [CMS] gVar - yandex_id
    yandexDebug: false,
    creatingOrder: false,
    paymentRules: {},
    enableExtraqualityCheck: false,
    enableOnePageSummary: false,
    predefaultState: {
        workType: '1',
        level: 1,
        paperFormat: 1,
        paperType: 1,
        resumeService: 1,
        subject: 0,
        pages: '1',
        spacing: 2,
        sources: '0',
        title: '',
        paperDetails: '',
        credentials: '',
        urgency: 240,
        resumeUrgency: 120,
        topWriters: 1,
        preferredWriters: 1,
        preferredWriterList: [],
        anotherWriter: true,
        qualityFeature: 1,
        extraqualityCheck: 1,
        onePageSummary: 1,
        discountCode: ''
    },
    editingOrderId: 0,
    editing: false,
    cloning: false,
    gridView: true,
    orderform: {
        stepEnabled: true
    },

    // Still debugging...
    siteStatus: {
        // id: 3,
        // name: 'Pending'
    },
    siteTransfer: {
        // appDashboard: 'app.studycrumb.devstage.cloud',
        // bulk: false,
        // dateStarted: '2021-08-20 00:00:00',
        // site: 'studycrumb.devstage.cloud'
    },
}

export default function globalReducer(state = initialState, { type, payload }) {
    switch (type) {
        // Dashboard
        case USER_AUTHORIZED:
            return {
                ...state,
                isAuthorized: true,
                thirdStepValid: true
            };

        case SIGN_IN_SUCCESS:
            return {
                ...state,
                isAuthorized: true
            };

        case SIGN_IN_FAILURE:
            return {
                ...state,
                isAuthorized: false
            };

        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthorized: false
            };

        case LOCK:
            return {
                ...state,
                locked: true
            };

        case UNLOCK:
            return {
                ...state,
                locked: false
            };

        case TOGGLE_MODAL:
            return {
                ...state,
                modalOpened: !state.modalOpened,
                modalComponent: (payload?.Component) || '',
                modalInfo: (payload?.modalInfo) || {}
            };

        case SET_VIEW:
            return {
                ...state,
                gridView: payload === 'grid' || false
            };

        case SET_VIEW_ON_RESIZE:
            return {
                ...state,
                gridView: payload
            };

        // Orderform
        case FETCH_DEFAULT_SETTINGS:
            return {
                ...state,
                siteCategory: payload.siteCategories[0],
                siteLanguage: payload.languageCode,
                siteId: payload.idSite,
                stepEnabled: payload.stepEnabled || getConfig(payload.siteCategories[0]).stepEnabled,
                mainColor: (payload.application_theme?.mainColor) || '',
                yandexID: payload.yandex_id || '',
                paymentRules: payload.payments_rules,
                siteDomain: payload.site_domain,
                supportEmail: payload.email,
                multipayment: payload.multipayment ? !!payload.multipayment : state.multipayment,
                messengerApiURL: payload.messages_url || '',
                orderMessengerApiURL: payload.baseOrderMessengerURL || '',
                messengerWebsoketURL: payload.baseWebSocketGatewayURL || '',
                messengerDisabled: payload.disableOrderMessenger || false,
                defaultsAlreadyFetched: true,
                enableExtraqualityCheck: payload.enableExtraQualityCheck || state.enableExtraqualityCheck,
                enableOnePageSummary: payload.enableOnePageSummary || state.enableOnePageSummary,
                siteTransfer: payload.siteTransfer,
                siteStatus: payload.siteStatus,
            };

        case USER_SIGN_UP_SUCCESS:
            return {
                ...state,
                signUpSuccess: true

            };

        case CREATE_ORDER:
            return {
                ...state,
                creatingOrder: !state.creatingOrder
            };

        case SELECT_OPTION + '_subject':
            return {
                ...state,
                firstStepValid: true
            };

        case FIRST_STEP_VALIDATION: // For Content category
            return {
                ...state,
                firstStepValid: true
            };

        case SECOND_STEP_VALIDATION:
            return {
                ...state,
                secondStepValid: payload
            };

        case THIRD_STEP_VALIDATION:
            return {
                ...state,
                thirdStepValid: payload
            };

        case SET_DEFAULT_VALUE:
            return {
                ...state,
                referralID: +payload.referralID || state.referralID,
                yandexDebug: payload.yandexDebug || state.yandexDebug,
                editing: payload.editing || state.editing,
                cloning: payload.cloning || state.cloning,
                editingOrderId: payload.editingOrderId || state.editingOrderId
            }

        case START_EDITING_ORDER:
            return {
                ...state,
                editing: true,
                editingOrderId: payload
            }

        case START_CLONING_ORDER:
            return {
                ...state,
                cloning: true
            }

        case CLEAR_ORDERFORM:
            return {
                ...state,
                editing: false,
                cloning: false,
                editingOrderId: 0
            }

        default:
            return state;
    }
}
