import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FeedbackRating from './FeedbackRating';

import { toggleModal } from '../../../store/global/globalActions';
import { setFeedbackComment, setFeedbackRating, setFeedback, clearFeedbackInfo } from '../../../store/dashboard/feedback/feedbackActions';

/**
 * @component
 * @category Dashboard
 * @subcategory Components
 * @hideconstructor
 */
function FeedbackModal({ id, type, rating, comment, setFeedbackComment, toggleModal, setFeedbackRating, setFeedback, clearFeedbackInfo }) {
    console.log('modalTYPE', type);
    console.log('modalID', id);

    const [orderRating, setOrderRating] = useState(rating);

    useEffect(() => {
        return () => {
            clearFeedbackInfo();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Set rating on hover
     * @param {number} hoverValue
     */
    const handleHover = (hoverValue) => {
        if (!hoverValue) {
            setOrderRating(rating);
        } else {
            setOrderRating(hoverValue);
        }
    }

    return (
        <div className="feedback-modal">
            <div className="modal__header">
                <label htmlFor="feedback-field" className="feedback__label">
                    We are constantly improving services, this is why your feedback is rather important for us.
                </label>
            </div>
            <div className="modal__body">
                <textarea
                    className="feedback__textarea"
                    name="feedback-field"
                    id="feedback-field"
                    cols="30"
                    rows="10"
                    value={comment}
                    onChange={setFeedbackComment}
                ></textarea>
                <FeedbackRating
                    rating={rating}
                    onChange={setFeedbackRating}
                    onHover={handleHover}
                />
                <span className="order-rating">
                    Order rating: <strong>{orderRating}</strong> of 5
                </span>
            </div>
            <div className="modal__footer">
                <button
                    className="modal__create"
                    onClick={setFeedback.bind(null, id, type)}
                >
                    Submit
                </button>
                <button
                    className="modal__close"
                    onClick={toggleModal}
                >
                    Close
                </button>
            </div>
        </div>
    )
}

FeedbackModal.propTypes = {
    id: PropTypes.number,
    type: PropTypes.string,
    rating: PropTypes.number,
    comment: PropTypes.string,
    setFeedbackComment: PropTypes.func,
    toggleModal: PropTypes.func,
    setFeedback: PropTypes.func,
    setFeedbackRating: PropTypes.func,
    clearFeedbackInfo: PropTypes.func
};

const mapStateToProps = ({ dashboard }) => ({
    rating: dashboard.feedback.rating,
    comment: dashboard.feedback.comment
});

const mapDispatchToProps = {
    toggleModal,
    setFeedbackComment,
    setFeedbackRating,
    setFeedback,
    clearFeedbackInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
