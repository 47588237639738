import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Collapse, Modal, Space } from 'antd';
import { useSiteTransfer } from 'services/transfer';
import { TransferHeader } from 'transfer/TransferHeader';
import { redirectTo } from 'helpers/history';

import styles from './TransferNotice.module.scss';

export const TransferNotice = () => {
  // Redux
  const { site, isManualTransfer } = useSiteTransfer();
  const isAppInited = useSelector(({ global }) => global.defaultsAlreadyFetched);
  const isAuthorized = useSelector(({ global }) => global.isAuthorized);
  const siteDomain = useSelector(({ global }) => global.siteDomain?.replace('https://', ''));

  // State
  const [opened, setOpened] = useState(false);
  const toggleOpen = () => setOpened(!opened);

  if (!isAppInited || !isManualTransfer) return null;

  return (
    <div>
      <div className={styles.Notification} onClick={toggleOpen}>
        <div className={styles.Notification__ring}></div>
        <div className={styles.Notification__badge}>
          <div className={styles.Notification__counter}>1</div>
        </div>
        <div className={styles.Notification__bell}></div>
      </div>

      <Modal className={styles.TransferNotice} visible={opened} footer={false} onCancel={toggleOpen}>
        {isAuthorized ? (
          <>
            <span className={styles.TransferNotice__heading}>
              Are you ready for a brand new academic experience? Meet our <em>new website, {site}!</em>
            </span>
            <TransferHeader utmSource="notice" />
            <div className={styles.TransferNotice__description}>
              You may transfer your account safely from this site to {site} through <strong>Settings</strong>{' '}
              → <strong> Transfer my account</strong> in your personal dashboard.
              <br />
              <br />
              All your personal details and orders will be transferred in one click.
            </div>
            <Space className={styles.TransferNotice__actions}>
              <Button shape="round" size="large" onClick={toggleOpen}>
                Stay on this Site
              </Button>
              <Button
                shape="round"
                type="success"
                size="large"
                onClick={() => {
                  redirectTo('/profile');
                  setOpened();
                }}
              >
                Initiate Transfer
              </Button>
            </Space>
          </>
        ) : (
          <>
            <span className={styles.TransferNotice__heading}>
              Impossible as it seemed <br />
              We boosted our service even further yet - <em>meet {site}!</em>
            </span>
            <TransferHeader utmSource="notice" />
            <Collapse ghost>
              <Collapse.Panel header="Already have an existing account?">
                Should you <strong>already have an existing account</strong> at {siteDomain} please{' '}
                <strong>Sign In</strong> and execute transfer of all your user data (through{' '}
                <strong>Settings</strong> → <strong> Transfer my account</strong> in your personal dashboard),
                and proceed with placing all your new orders at {site}.
              </Collapse.Panel>
            </Collapse>
          </>
        )}
      </Modal>
    </div>
  );
};
