import { combineReducers } from 'redux';

import profile from 'store/dashboard/profile/profileReducer';
import nav from 'store/dashboard/nav/navReducer';
import orders from 'store/dashboard/orderlist/ordersReducer';
import orderinfo from 'store/dashboard/orderinfo/orderinfoReducer';
import files from 'store/dashboard/orderinfo/files/filesReducer';
import revisions from 'store/dashboard/orderinfo/revisions/revisionReduser';
import transactions from 'store/dashboard/transactionlist/transactionsReducer';
import feedback from 'store/dashboard/feedback/feedbackReducer';
import messages from 'store/dashboard/orderinfo/messenger/messengerReducer';
import ordermessenger from 'store/dashboard/orderinfo/ordermessenger/orderMessengerReducer';
import loyalty from 'store/dashboard/loyalty/loyaltyReducer';
import referral from 'store/dashboard/referral/referralReducer';

const dashboardReducer = combineReducers({
  profile,
  nav,
  orders,
  orderinfo,
  files,
  revisions,
  transactions,
  feedback,
  messages,
  ordermessenger,
  loyalty,
  referral,
});

export default dashboardReducer;
