import ReconnectingWebSocket from 'reconnecting-websocket';
import { socketURL } from 'store/config';
import { setOnlineStatus, updateMessage, updateUnreadCount, toggleType } from 'store/dashboard/orderinfo/ordermessenger/orderMessengerActions';
import { store } from '../index';

export default class WS {
    static _rws;
    static _needRefresh = false;

    static _pingInterval;
    static _pongInterval;
    static PING_INTERVAL = 300;
    static PONG_TIMEOUT = 10;

    static _requestWriterInterval;

    static _getAccessToken = async () => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const error = new Error();

        error.message = 'Access Token is missing';
        error.stopRefresh = true;

        if (!accessToken && !refreshToken) return WS.close(3000, 'Both tokens are missing');
        if (!accessToken) return WS._refreshToken(refreshToken);
        if (!this._needRefresh) return accessToken;

        return WS._refreshToken(refreshToken);
    }

    static _refreshToken = async (refreshToken) => {
        const refreshUrl = '/api/en/auth/jwt/refresh';
        const error = new Error();

        error.message = 'Invalid refresh';
        this._needRefresh = false;

        try {
            const res = await fetch(refreshUrl, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`
                }
            })

            if ([401].includes(res.status)) throw error;

            if ([400, 403, 404, 422].includes(res.status)) {
                // Error handler
                error.message = "Bad response from server on refresh";
                error.body = await res.json();

                throw error;
            }

            if (res.status >= 400 && res.status < 600) throw error;

            // Handle response
            const data = await res.json();

            data.accessToken && localStorage.setItem('accessToken', data.accessToken);
            data.refreshToken && localStorage.setItem('refreshToken', data.refreshToken);

            return data.accessToken;

        } catch (err) {
            return Promise.reject(err);
        }
    }

    static _urlProvider = async () => {
        const { global: { messengerWebsoketURL } } = store.getState();
        let token;

        try {
            token = await WS._getAccessToken();

        } catch (error) {
            console.log('Catched error>>', error)
            WS.close(3001, 'Close on refresh fail')
        }

        return socketURL(messengerWebsoketURL, token);
    }

    static _onOpen = (e) => {
        // console.log("open socket>>", e)
        WS._ping();
    }
    static _onClose = (e) => {
        // console.log("close socket>>", e)
    }
    static _onMessage = (e) => {
        const EVENT = {
            MESSAGE: 1,
            PONG: 3,
            COUNTER: 5,
            WRITER_ONLINE_REQUEST: 7,
            WRITER_ONLINE_RESPONSE: 8,
            // CUSTOMER_TO_USER_START_TYPING: 11,
            // CUSTOMER_TO_USER_STOP_TYPING: 12,
            USER_TO_CUSTOMER_START_TYPING: 13,
            USER_TO_CUSTOMER_STOP_TYPING: 14
        }

        const handleMessageEvents = {
            [EVENT.MESSAGE]: (message) => {
                updateMessage(message)(store.dispatch, store.getState);
            },
            [EVENT.PONG]: () => {
                clearInterval(this._pongInterval);
                WS._ping();
            },
            [EVENT.COUNTER]: (data) => {
                updateUnreadCount(data)(store.dispatch, store.getState);
            },
            [EVENT.WRITER_ONLINE_RESPONSE]: (data) => {
                setOnlineStatus(data.online)(store.dispatch);
                clearInterval(this._responseWriterInterval);
            },
            [EVENT.USER_TO_CUSTOMER_START_TYPING]: (data) => {
                toggleType(data.order.id, true)(store.dispatch, store.getState);
            },
            [EVENT.USER_TO_CUSTOMER_STOP_TYPING]: (data) => {
                toggleType(data.order.id, false)(store.dispatch, store.getState);
            }
        }

        // console.log("message socket>>", JSON.parse(e.data))
        const data = JSON.parse(e.data);
        const event = data.event.id;

        delete data.event;

        handleMessageEvents[event](data);
    }
    static _onError = (e) => {
        console.log("error socket>>", e)
        this._needRefresh = true;
    }

    static _startInterval = (intervalVar, cb, timeout) => {
        clearInterval(this[intervalVar]);
        this[intervalVar] = setInterval(cb, timeout);
    }

    static _ping = () => {
        // Init ping interval
        let pingTimer = 0;

        WS._startInterval('_pingInterval', () => {
            // console.log('ping', pingTimer)

            if (pingTimer === this.PING_INTERVAL) {
                pingTimer = 0;

                // Init Wait PONG timer
                let pongTimer = 0;

                WS._startInterval('_pongInterval', () => {
                    // console.log('pong tick', pongTimer);

                    if (pongTimer === this.PONG_TIMEOUT) {
                        pongTimer = 0;
                        WS.reconnect();

                    } else {
                        pongTimer++;
                    }

                }, 1000);

                // Send PING
                WS.send(JSON.stringify({ event: { id: 2, name: 'Ping' } }));

            } else {
                pingTimer++;
            }
        }, 1000);
    }

    static requestWriterOnline = (writerId) => {
        WS.send(JSON.stringify({ event: { id: 7, name: 'Writer online request' }, writer: { id: writerId } }));

        WS._startInterval('_requestWriterInterval', () => {
            WS.send(JSON.stringify({ event: { id: 7, name: 'Writer online request' }, writer: { id: writerId } }));
        }, 60000);
    }

    static typing = (currentChat, orderId, customerId, state) => {
        const eventObj = {
            start: {
                id: 11,
                name: "Customer to user start typing"
            },
            stop: {
                id: 12,
                name: "Customer to user stop typing"
            }
        }

        const eventMessage = {
            support: {
                event: eventObj[state],
                order: {
                    id: orderId,
                    customer: {
                        id: customerId
                    }
                }
            },
            writer: {}
        }

        currentChat === 'support' && WS.send(JSON.stringify(eventMessage[currentChat]));
    }

    static connect = () => {
        this._rws = new ReconnectingWebSocket(WS._urlProvider, [], { debug: false });

        // Add listeners
        this._rws && this._rws.addEventListener('open', WS._onOpen);
        this._rws && this._rws.addEventListener('close', WS._onClose);
        this._rws && this._rws.addEventListener('message', WS._onMessage);
        this._rws && this._rws.addEventListener('error', WS._onError);
    }

    static close = (code, reason) => {
        clearInterval(this._pingInterval);
        clearInterval(this._pongInterval);
        clearInterval(this._requestWriterInterval);
        this._rws.close(code, reason);
    }

    static send = (data) => {
        // console.log('send', data)
        this._rws.send(data);
    }

    static reconnect = (code, reason) => {
        clearInterval(this._pingInterval);
        clearInterval(this._pongInterval);
        clearInterval(this._requestWriterInterval);
        this._rws.reconnect(code, reason);
    }

    static removeListeners = () => {
        this._rws && this._rws.removeEventListener('open', WS._onOpen);
        this._rws && this._rws.removeEventListener('close', WS._onClose);
        this._rws && this._rws.removeEventListener('message', WS._onMessage);
        this._rws && this._rws.removeEventListener('error', WS._onError);
    }
}