/**
 * @module LoginActions
 */
import http from 'services/http';
import { SIGN_IN_SUCCESS, USER_AUTHORIZED, SIGN_IN_FAILURE } from 'store/actionTypes';
import { customerURL, signupURL } from 'store/config';
import { fetchPreferredWriter } from 'store/orderform/details/preferredwriter/preferredWriterAction';
import { BASE_URL } from 'const';
import { notify } from 'helpers/notification';
import { tokenService } from 'services/tokenService';
import { store } from 'index';
import { getFingerprint } from 'helpers/utils';
import { checkSiteTransfer } from 'services/transfer';
// import { cookieService } from 'services/cookieService';

// Legacy capability
const updateUserDropdownMenu = () => {
  if (document.body.id !== 'site') return; // isn't builtin
  console.log('Legacy > Legacy layout detected!');

  const isLoggedIn = document.body.classList.contains('is-loggedin');
  console.log('Legacy > isLoggedIn', isLoggedIn);
  if (isLoggedIn) return; // already logged in

  console.log('Legacy > Not logged in');
  const params = new URLSearchParams(window.location.search);

  // Remove magicLink from search params
  try {
    params.delete('magicLink'); // Remove used magicLink from search params
    const qs = params.toString();
    const { pathname } = window.location;
    const url = `${pathname}${qs.length > 1 ? `?${qs}` : ''}`;
    window.history.replaceState(null, null, url);
  } catch (error) {
    console.error(error);
  }

  const returnPage = params.get('returnpage');
  returnPage && window.history.replaceState(null, null, `/${returnPage}`);

  // Instead of reload the page -> update only header
  fetch(window.location.href)
    .then((r) => r.text())
    .then((html) => {
      const targetId = 'masthead';
      const parser = new DOMParser(html);
      const doc = parser.parseFromString(html, 'text/html');
      const parsedHeader = doc.getElementById(targetId);
      const header = document.getElementById(targetId);
      header.innerHTML = parsedHeader.innerHTML;
    });

  console.log('updateUserDropdownMenu > Updated header');
  return true;
};

/** */
export function checkUser() {
  return async function (dispatch) {
    const token = tokenService.getAccessToken();

    if (!token) return;

    return http
      .get(customerURL)
      .then((user) => {
        if (user?.id) {
          updateUserDropdownMenu();
          dispatch({ type: USER_AUTHORIZED, payload: user });
          fetchPreferredWriter()(dispatch);
        } else {
          console.log('Login failed. Expected user.id', user);
          return tokenService.clearTokens();
        }
      })
      .catch((err) => console.log(err));
  };
}

export function checkMagicLogin() {
  return async function () {
    const searchParams = new URLSearchParams(window.location.search);
    const magicLink = searchParams.get('magicLink');

    if (!magicLink) return;

    return fetch(`${BASE_URL}/api/en/auth/jwt/magic_link/${magicLink}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.accessToken) {
          tokenService.setTokens(data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(updateUserDropdownMenu);
  };
}

export function login(credentials) {
  return async function (dispatch) {
    return fetch(`${BASE_URL}/api/en/auth/jwt/sign_in`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
      .then((response) => response.json())
      .then(async (response) => {
        console.log(`login response`, response);
        if (await checkSiteTransfer(response)) return; // stop login if site transfer

        // Login success
        if (!response.accessToken) {
          dispatch({ type: SIGN_IN_FAILURE });
          throw new Error(response?.message || 'Login failed');
        }

        // Set tokens
        http.setAccessToken(response.accessToken);
        tokenService.setTokens(response);
        if (!updateUserDropdownMenu()) dispatch({ type: SIGN_IN_SUCCESS });
        return credentials;
      })
      .catch((response) => {
        console.log(`login error`, response);
        notify.error(response.message); // show notification
        // * Reject without args, coz this is a main handler
        return Promise.reject();
      });
  };
}

export function logout() {
  return async (dispatch) => {
    await tokenService.clearTokens();

    // <- fix some issues with initial state after logout
    window.location.reload(); // instead dispatch "LOGOUT_SUCCESS"

    // dispatch({ type: LOGOUT_SUCCESS }); <- coz refresh page
  };
}

export async function signUp(payload) {
  const hashDevice = await getFingerprint();
  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...payload,
      hashDevice,
    }),
  };

  return fetch(signupURL, request)
    .then((resp) => resp.json())
    .then(async (tokens) => {
      if (await checkSiteTransfer(tokens)) return; // stop login if site transfer

      // Check for server errors
      const { errors, message } = tokens;
      if (errors || message) {
        throw new Error(errors ? 'Check form fields' : message);
      }

      // Set tokens & update headers (http service)
      tokenService.setTokens(tokens);
      http.setAccessToken(tokens.accessToken);

      store.dispatch(checkUser()); // update store dashboard -> profile

      return tokens;
    });
}
