import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../pages/Login';
import { useSelector } from 'react-redux';

/**
 * @component
 * @hideconstructor
 */
export function PrivateRoute({ component: Component, ...rest }) {
  const isAuthorized = useSelector(({global})=>global.isAuthorized)
    return (
        <Route {...rest}
            render={props => (
                isAuthorized ? <Component {...props} /> : <Login />
            )}
        />
    )
}


export default PrivateRoute;
