import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import CalendarIcon from '../../../../components/img/CalendarIcon';
import WatchIcon from '../../../../components/img/WatchIcon';
import { setRevisionLevel } from '../../../../../store/dashboard/orderinfo/revisions/revisionActions';



/**
 * @name -revisions/ThirdStep
 * @component
 * @category Dashboard
 * @subcategory Orderinfo
 * @hideconstructor
 * @memberof Revisions
 */
function ThirdStep({ pages, levelsList, pickedRevisionLevel, setRevisionLevel }) {

    /**
     * Fix accoding to task https://raise-band.atlassian.net/browse/DEV-1244
     * Params not required. Depends on props: pages & pickedRevisionLevel (mapStateToProps)
     * @returns calculated deadline time in minutes
     */
    const getDeadlineInMinutes = () => {
        if (!pickedRevisionLevel) return 0
        const levelTime = {1: 12 * 60, 2: 16 * 60, 3: 20 * 60, 4: 24 * 60} // revision level hours, key: revisionLevel, val: hrs
        const ratioTime = {1: 20, 2: 40, 3: 80, 4: 120}
        return (levelTime[pickedRevisionLevel] + ratioTime[pickedRevisionLevel] * (pages - 1))
    }

    /** 
     * Depends on moment & getDeadlineInMinutes
     * @returns formated due date MMM DD, hh:mm
     * */ 
    const getFormatedETA = () => {
        return moment().add(getDeadlineInMinutes(), 'minutes').format('MMM DD, HH:mm')
    }

    /**
     * Params not required. Depends on getDeadlineInMinutes()
     * @returns formated ETA time 00:00 hr(s) from minutes
     */
    const getTimeRequired = () => {
        const num = getDeadlineInMinutes()
        const h = (num / 60);
        const hours = Math.floor(h);
        const m = (h - hours) * 60;
        const minutes = String(Math.round(m));
        return `${hours}:${minutes.length === 1 ? '0' : ''}${minutes} hr(s)`;
    }

    return (
        <>
            <h3 className="modal__title">Revision complexity level</h3>
            <p className="modal__text">
                Revision complexity level affects the time required to complete your revision. We Strongly recommended that you select proper revision level to ensure best outcome within the shortest time-frame.
            </p>
            <div className="modal__complexity">
                <div className="modal__complexity-left">
                    <ul className="levels">
                        {levelsList.map(level => (
                            <li className="levels__item" key={level.id}>
                                <input
                                    id={`revision-level-${level.id}`}
                                    className="levels__input"
                                    type="radio"
                                    checked={pickedRevisionLevel === level.id}
                                    name="revision-level"
                                    onChange={setRevisionLevel.bind(null, level.id)}
                                />
                                <label
                                    htmlFor={`revision-level-${level.id}`}
                                    className="levels__label"
                                >
                                    {level.name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="modal__complexity-right">
                    <div className="complexity__time">
                        <span className="label">
                            <WatchIcon />
                            Time Required:
                        </span>
                        <span className="content">
                            {getTimeRequired()}
                        </span>
                    </div>
                    <div className="complexity__eta">
                        <span className="label">
                            <CalendarIcon />
                            ETA:
                        </span>
                        <span className="content">
                            {getFormatedETA()}
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

ThirdStep.propTypes = {
    levelsList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    })),
    pickedRevisionLevel: PropTypes.number,
    setRevisionLevel: PropTypes.func
}

const mapStateToProps = ({ dashboard }) => ({
    levelsList: dashboard.revisions.levelsList,
    pages: dashboard.orderinfo.order.pages,
    pickedRevisionLevel: dashboard.revisions.pickedRevisionLevel
});

const mapDispatchToProps = {
    setRevisionLevel
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep);