import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { checkUser, login } from 'store/login/loginActions';
import { getUTM, useSiteTransfer } from 'services/transfer';
import { getUrlParams } from 'helpers/utils';
import { TransferHeader } from '../transfer/TransferHeader';

// Validations
const validators = {
  email: [
    { required: true, message: 'Email is mandatory' },
    { type: 'email', message: 'Provide valid email address' },
  ],
  password: [{ required: true, message: "Password can't be empty" }],
};

export default function Login() {
  // Store
  const dispatch = useDispatch();
  const { isAuthorized } = useSelector((state) => state.global);
  const siteTransfer = useSiteTransfer();

  // State
  const [isProcessing, setIsProcessing] = useState(false); // loading spinner

  // Form defaults from url search params
  const initialValues = getUrlParams();

  // Handle login
  const handleLogin = (credentials) => {
    setIsProcessing(true); // start spinner
    dispatch(login(credentials)) // login with credentials
      .then(() => dispatch(checkUser()))
      .then(()=>window.history.replaceState(null, null, '/dashboard'))
      .catch(() => setIsProcessing(false)); // stop spinner
  };

  const redirectToTransferredSite = () => {
    window.location.href = `${siteTransfer.targetUrl}/login?${getUTM('login')}`;
  };

  if (isAuthorized) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="login login__wrapper">
      {siteTransfer.isAlreadyTransferred ? (
        <>
          <TransferHeader utmSource="login" />
          <div className="login__hint">We have moved to a new website!</div>
        </>
      ) : (
        <h1 className="login__heading simple">
          <LockOutlined />
        </h1>
      )}

      <Form
        size="large"
        onFinish={handleLogin}
        initialValues={initialValues}
        className="login__form"
        noValidate
      >
        <Form.Item name="email" rules={validators.email}>
          <Input className="login__input" type="email" placeholder="Your email" prefix={true} autoFocus />
        </Form.Item>
        <Form.Item name="password" rules={validators.password}>
          <Input.Password className="login__input" type="password" placeholder="Your password" />
        </Form.Item>
        <Form.Item noStyle bordered={false}>
          <Button
            loading={isProcessing}
            className="login__btn btn btn-primary"
            htmlType="submit"
            type="primary"
            size="large"
          >
            Proceed
          </Button>
        </Form.Item>
        <div className="login__link">
          {siteTransfer.isAlreadyTransferred ? (
            <span className="clickable" onClick={() => redirectToTransferredSite()}>
              Forgot password? ↗
            </span>
          ) : (
            <>
              <div>
                <Link className="link" to="/forgot">Forgot password?</Link>
              </div>
              <Link className="link" to="/order">Sign up with order</Link>
              <span>or</span>
              <Link className="link" to="/register">Register</Link>
            </>
          )}
        </div>
      </Form>
    </div>
  );
}
