import React from 'react';
import { getUTM, useSiteTransfer } from 'services/transfer';
import PropTypes from 'prop-types';

import styles from './TransferHeader.module.scss'

export const TransferHeader = ({ utmSource }) => {
  const { targetUrl, site } = useSiteTransfer();

  const redirectToTransferedSite = () => {
    window.location.href = `${targetUrl}${window.location.pathname}?${getUTM(utmSource)}`;
  };

  return (
    <div className={styles.TransferHeader}>
      <a className={styles.TransferHeader__source} href="/">
        <img src="/static/img/logo_white.svg" alt="logo" />
      </a>
      <span className={styles.TransferHeader__arrow} role="img" aria-label="to">👉</span>
      <span className={styles.TransferHeader__target} onClick={() => redirectToTransferedSite()}>
        <img src={`https://${site}/static/img/logo.svg`} alt=""/>
      </span>
    </div>
  );
};

TransferHeader.defaultProps = {};
TransferHeader.propTypes = {
  utmSource: PropTypes.string.isRequired,
};
