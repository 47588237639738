/**
 * @module RevisionActions
 */
import {
    FETCH_REVISION_CATEGORIES,
    FETCH_REVISION_LEVELS,
    FETCH_REVISION_LIST,
    FETCH_REVISION_BY_ID,
    FETCH_REVISION_CUSTOMER_FILES,
    FETCH_REVISION_WRITER_FILES,
    CLEAR_REVISIONS,
    CLEAR_ACTIVE_REVISION,
    ADD_REVISION_FILE,
    ADD_REVISION_FILE_ERROR,
    CLEAR_REVISION_FILE,
    SET_REVISION_LEVEL,
    CHECK_REVISION_POLICY,
    SET_REVISION_CATEGORY,
    SET_REVISION_DESCRIPTION,
    TOGGLE_MODAL,
    LOCK,
    UNLOCK,
    CREATE_REVISION
} from 'store/actionTypes';

import { revisionListURL, revisionInfoByIdURL, revisionCustomersFilesURL, revisionWritersFilesURL, createRevisionURL, uploadRevisionFileURL, revisionCategoriesURL, revisionLevelsURL } from 'store/config';
import { notify } from 'helpers/notification';
import http from 'services/http';

export function getRevisionList(config, dispatch) {
    return http
        .get(revisionListURL(config))
        .then(data => {
            dispatch({
                type: FETCH_REVISION_LIST,
                payload: data
            });
        })
        .catch(err => console.log(err))
}

/** */
export function fetchRevisionCategories() {
    return function (dispatch) {
        return http
            .get(revisionCategoriesURL)
            .then(data => {
                dispatch({
                    type: FETCH_REVISION_CATEGORIES,
                    payload: data
                })
            })
            .catch(err => console.log(err))
    }
}

/** */
export function fetchRevisionLevels() {
    return function (dispatch) {
        return http
            .get(revisionLevelsURL)
            .then(data => {
                dispatch({
                    type: FETCH_REVISION_LEVELS,
                    payload: data
                })
            })
            .catch(err => console.log(err))
    }
}

/** */
export function fetchRevisionList(config) {
    return function (dispatch) {
        return getRevisionList(config, dispatch);
    }
}

/** */
export function fetchRevisionInfoById(revisionId) {
    return function (dispatch) {
        return http
            .get(revisionInfoByIdURL(revisionId))
            .then(data => {
                dispatch({
                    type: FETCH_REVISION_BY_ID,
                    payload: data
                });
            })
            .catch(err => console.log(err))
    }
}

/** */
export function clearRevisions() {
    return function (dispatch) {
        dispatch({ type: CLEAR_REVISIONS })
    }
}

/** */
export function clearActiveRevision() {
    return function (dispatch) {
        dispatch({ type: CLEAR_ACTIVE_REVISION })
    }
}

/** */
export function fetchRevisionCustomerFiles(revisionId) {
    return function (dispatch) {
        return http
            .get(revisionCustomersFilesURL(revisionId))
            .then(data => {
                dispatch({
                    type: FETCH_REVISION_CUSTOMER_FILES,
                    payload: data
                });
            })
            .catch(err => console.log(err))
    }
}

/** */
export function fetchRevisionWriterFiles(revisionId) {
    return function (dispatch) {
        return http
            .get(revisionWritersFilesURL(revisionId))
            .then(data => {
                dispatch({
                    type: FETCH_REVISION_WRITER_FILES,
                    payload: data
                });
            })
            .catch(err => console.log(err))

    }
}

/** */
export function addRevisionFile(e) {
    return function (dispatch, getState) {
        const { dashboard: { revisions } } = getState();
        e.persist();
        e.target.blur();

        if (!e.target.files[0]) {
            dispatch({ type: CLEAR_REVISION_FILE })
            return;
        }

        let fileSize = e.target.files[0].size,
            calcSize;

        function formatSize(size, unit) {
            return (
                calcSize = ((fileSize / size) % 1 === 0) ?
                    `${fileSize / size}${unit}` :
                    `${(fileSize / size).toFixed(1)}${unit}`
            )
        }

        function fileValidation(validTypes, fileName) {
            const extention = fileName && fileName.toLowerCase().substring(fileName.lastIndexOf('.'), fileName.length);
            const result = extention && validTypes.some((item) => item === extention);
            return !!result;
        }

        if (!fileValidation(revisions.validFileTypes, e.target.files[0].name)) {
            dispatch({
                type: ADD_REVISION_FILE_ERROR
            })

            notify.warn(`${revisions.validFileTypes.join(', ')}`, 'Invalid file format. Acceptable file formats:');
            return;
        }

        if (fileSize > 104857600) {
            dispatch({
                type: ADD_REVISION_FILE_ERROR
            })

            notify.warn('Maximum file size is 100MB')
            return;
        }

        if (fileSize < 1024) {
            calcSize = `${fileSize}b`;

        } else if (fileSize < 1048576) {
            formatSize(1024, 'kb');

        } else {
            formatSize(1048576, 'mb');
        }

        dispatch({
            type: ADD_REVISION_FILE,
            payload: {
                fileObj: e.target.files[0],
                fileSize: calcSize,
                fileName: e.target.files[0].name
            }
        });
    }
}

/** */
export function clearRevisionFile(fileInput) {
    return function (dispatch) {
        fileInput.value = '';
        dispatch({ type: CLEAR_REVISION_FILE });
    }
}

/** */
export function setRevisionLevel(id) {
    return function (dispatch) {
        dispatch({
            type: SET_REVISION_LEVEL,
            payload: +id
        });
    }
}

/** */
export function checkRevisionPolicy() {
    return function (dispatch) {
        dispatch({
            type: CHECK_REVISION_POLICY
        });
    }
}

/** */
export function setRevisionCategory(categoryId) {
    return function (dispatch) {
        dispatch({
            type: SET_REVISION_CATEGORY,
            payload: categoryId
        });
    }
}

/** */
export function setRevisionDescription(e) {
    e.persist();
    return function (dispatch) {
        dispatch({
            type: SET_REVISION_DESCRIPTION,
            payload: e.target.value
        });
    }
}

/** */
export function createRevision(thirdStepValid) {
    return function (dispatch, getState) {
        const { dashboard: { revisions, orderinfo } } = getState();

        const data = {
            category: revisions.pickedRevisionCategory,
            describeProblem: revisions.revisionDescription,
            revisionLevel: revisions.pickedRevisionLevel
        }

        if (thirdStepValid) {
            dispatch({ type: LOCK });

            return http
                .post(createRevisionURL(orderinfo.order.id), {
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                })
                .then(res => {
                    if (res.id) {
                        if (revisions.uploadedFileName) {
                            const formData = new FormData();

                            formData.append('id_revision', res.id);
                            formData.append('file', revisions.uploadedFile);

                            return http
                                .post(uploadRevisionFileURL(res.id), { body: formData })
                                .then(() => {
                                    dispatch({ type: TOGGLE_MODAL });
                                    dispatch({ type: CREATE_REVISION });
                                    notify.success('Revision has been successfully created');

                                    getRevisionList({
                                        idOrder: orderinfo.order.id,
                                        page: 1,
                                        limit: 10
                                    }, dispatch);
                                })
                                .catch(err => console.log(err))
                                .finally(() => dispatch({ type: UNLOCK }))

                        } else {
                            dispatch({ type: TOGGLE_MODAL });
                            dispatch({ type: CREATE_REVISION });
                            notify.success('Revision has been successfully created');

                            getRevisionList({
                                idOrder: orderinfo.order.id,
                                page: 1,
                                limit: 10
                            }, dispatch);
                        }
                    }
                })
                .catch(err => console.log(err))
                .finally(() => dispatch({ type: UNLOCK }))
        }
    }
}