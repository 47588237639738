import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { http } from 'services/http';
import { orderListURL } from '../store/config';
import { getValueFromQueryString } from 'hooks/useSearchParams';

/**
 * @component
 * @category Dashboard
 * @hideconstructor
 */
export function OrderinfoRedirect() {
  // This component is just for redirect form /orderinfo?id=E-12354687 to /orderinfo/123456/details
  const siteCategory = useSelector(({ global }) => global.siteCategory);
  const history = useHistory();
  const id = getValueFromQueryString('id');

  const navigateToOrderList = () => history.push('/dashboard');

  useEffect(() => {
    if (id) {
      http
        .get(orderListURL(siteCategory, { limit: 1, page: 1, q: id }))
        .then((data) => {
          if (data?.orders[0]) {
            history.push(`/orderinfo/${data.orders[0].id}/details`);
          }
        })
        .catch((err) => {
          console.error(err);
          navigateToOrderList();
        });
    } else navigateToOrderList();
  });

  return null;
}

export default OrderinfoRedirect;
