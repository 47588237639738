import React from 'react';
import { useSiteTransfer } from 'services/transfer';
import { TransferHeader } from './TransferHeader';

import styles from './TransferBanner.module.scss';

export const TransferBanner = () => {
  const { isAlreadyTransferred, site } = useSiteTransfer();
  if (!isAlreadyTransferred) return null;

  return (
    <div className={styles.TransferBanner}>
      <span className={styles.TransferBanner__heading}>
        WE HAVE MOVED TO A <em>NEW WEBSITE!!!</em>
      </span>
      <TransferHeader utmSource="banner" />
      <span className={styles.TransferBanner__description}>
        All your data has been transferred to {site}.
      </span>
      <span className={styles.TransferBanner__description}>
        Come visit our shiny new website to get the most of our services.
      </span>
    </div>
  );
};
