import React from 'react';

export const BadgeDot = ({ children, show, type, wrapperProps, ...dotProps }) =>
  !show ? (
    children
  ) : (
    <span className={`BadgeDot__wrapper ${type}`} {...wrapperProps}>
      {children}
      <span className="BadgeDot__dot" {...dotProps} />
    </span>
  );
