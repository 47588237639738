import {
    FETCH_WORK_TYPE,
    SET_WORK_TYPE,
    SET_DEFAULT_VALUE
} from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    name: 'workType',
    value: '1',
    types: [],
    label: 'Select Type of Service',
    workTypeList: {
        1: { title: 'Writing', subtitle: '' },
        2: { title: 'Editing & Proofreading', subtitle: '', tooltip: 'We will thoroughly proofread the content of your work and also may change or rewrite up to 30% of the original content' },
        3: { title: 'Presentation', subtitle: '' },
        4: { title: 'Review', subtitle: '' }
    },
    alreadyFetched: false
}

export default function workTypeReducer(state = initialState, { type, payload, siteCategory }) {
    switch (type) {
        case FETCH_WORK_TYPE:
            if (siteCategory === 5) {
                return {
                    ...state,
                    types: payload,
                    workTypeList: {
                        ...state.workTypeList,
                        2: {
                            title: 'Editing or Proofreading',
                            subtitle: '',
                            tooltip: 'We will thoroughly proofread the content of your work and also may change or rewrite up to 30% of the original content'
                        }
                    },
                    alreadyFetched: true
                };

            } else {
                return {
                    ...state,
                    types: payload,
                    alreadyFetched: true
                };
            }

        case SET_WORK_TYPE:
            return {
                ...state,
                value: payload
            };

        case SET_DEFAULT_VALUE:
            return {
                ...state,
                value: payload.workType || state.value
            }

        case CLEAR_ORDERFORM:
            return {
                ...state,
                value: payload.workType
            }

        default:
            return state;
    }
}