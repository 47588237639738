import { version } from '../package.json';

export const siteStatuses = {
  enabled: 1,
  disabled: 2,
  pending: 3,
  transferred: 4,
};

// Define environment variable in .env file
export const BASE_URL = process.env.REACT_APP_BASE_URL || ''; // API
export const HOME_URL = process.env.REACT_APP_HOME_URL || ''; // HOMEPAGE
export const GA_CODE = process.env.REACT_APP_GA_CODE || ''; // Google analytics code
export const GTM_CODE = process.env.REACT_APP_GTM_CODE || ''; // GTM Code
export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR; // Primary color as --main-color
export const SITE_NAME = process.env.REACT_APP_SITENAME;


export const APP_VERSION = version;
