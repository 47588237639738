import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Checkbox from '../../../../../orderform/components/checkbox/Checkbox';

import { checkRevisionPolicy } from '../../../../../store/dashboard/orderinfo/revisions/revisionActions';

/**
 * @name -revisions/FirstStep
 * @component
 * @category Dashboard
 * @subcategory Orderinfo
 * @hideconstructor
 * @memberof Revisions
 */
function FirstStep({ uid, acceptPolicy, checkRevisionPolicy }) {
    return (
        <>
            <h3 className="modal__title">Submit {uid} for free revision</h3>
            <p className="modal__text">
                A free revision implies that the paper was not written with full regard to your initial paper instructions. Kindly note that you may not ask the writer to introduce additional changes to the paper if they were not included in the paper details box while you were placing order.
            </p>
            <p className="modal__text">
                Although we provide premium quality academic assistance, it may be necessary to have the delivered product revised. You are entitled to request as many revisions as may be required to make the paper consistent and compliant with your instructions. We offer a 14-day Revision Period for papers less than 30 pages and a 30-day period for papers more than 30 pages. In case a request for revision is not submitted within the stated period, we accept the fact that the client is satisfied with the paper and requires no further actions to be taken in regards to the paper unless extra payment is provided or a new order is placed.
            </p>
            <div className="modal__checkbox">
                <Checkbox
                    id="policy"
                    type="checkbox"
                    checked={acceptPolicy}
                    toggleCheck={checkRevisionPolicy}
                    disabled={false}
                >
                    <span>I've read and accept <a className="checkbox__link" href="/terms-and-conditions" target="_blank" rel="noopener nofollow">revision policy</a>.</span>
                </Checkbox>
            </div>
        </>
    )
}

FirstStep.propTypes = {
    uid: PropTypes.string,
    acceptPolicy: PropTypes.bool,
    checkRevisionPolicy: PropTypes.func
}

const mapStateToProps = ({ dashboard }) => ({
    acceptPolicy: dashboard.revisions.acceptPolicy,
    uid: dashboard.orderinfo.order.uid
});

const mapDispatchToProps = {
    checkRevisionPolicy
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstStep);