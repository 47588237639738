export const USER_AUTHORIZED = '[LOGIN] USER_AUTHORIZED';
export const LOCK = '[HELPER] LOCK';
export const UNLOCK = '[HELPER] UNLOCK';

export const TOGGLE_MODAL = '[GLOBAL] TOGGLE_MODAL';
export const SET_VIEW = '[GLOBAL] SET_VIEW';
export const SET_VIEW_ON_RESIZE = '[GLOBAL] SET_VIEW_ON_RESIZE';
export const SET_PENDING_STATUS = '[GLOBAL] SET_PENDING_STATUS';

export const GO_TO_DASHBOARD = '[NOT_FOUND] GO_TO_DASHBOARD';

export const SIGN_UP_SUCCESS = '[LOGIN] SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = '[LOGIN] SIGN_UP_FAILURE';
export const SIGN_IN_SUCCESS = '[LOGIN] SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = '[LOGIN] SIGN_IN_FAILURE';
export const LOGOUT_SUCCESS = '[LOGIN] LOGOUT_SUCCESS';

// PROFILE
export const SET_PROFILE_PHONE = '[PROFILE] SET_PROFILE_PHONE';
export const SET_PROFILE_FIRST_NAME = '[PROFILE] SET_PROFILE_FIRST_NAME';
export const TOGGLE_PROFILE_NEWS_LETTERS = '[PROFILE] TOGGLE_PROFILE_NEWS_LETTERS';
export const TOGGLE_PROFILE_NIGHT_CALLS = '[PROFILE] TOGGLE_PROFILE_NIGHT_CALLS';
export const UPDATE_PROFILE = '[PROFILE] UPDATE_PROFILE';
export const SET_PREFERENCES = '[PROFILE] SET_PREFERENCES';
export const DELETE_PROFILE = '[PROFILE] DELETE_PROFILE';
export const SEND_CONFIRMATION = '[PROFILE] SEND_CONFIRMATION';
export const TOGGLE_PROFILE_FB_MESSENGER = '[PROFILE] TOGGLE_PROFILE_FB_MESSENGER';
export const DISABLED_PROFILE_FB_MESSENGER = '[PROFILE] DISABLED_PROFILE_FB_MESSENGER';
export const ENABLED_PROFILE_FB_MESSENGER = '[PROFILE] ENABLED_PROFILE_FB_MESSENGER';
export const LOADING_FACEBOOK_SCRIPTS = '[PROFILE] LOADING_FACEBOOK_SCRIPTS';
export const UPDATE_PROFILE_STATUS = '[PROFILE] UPDATE_PROFILE_STATUS'; // PREV STATE

// DASHBOARD
export const FETCH_ORDER_LIST = '[DASHBOARD] FETCH_ORDER_LIST';
export const GET_ORDERS_COUNT = '[DASHBOARD] GET_ORDERS_COUNT';
export const SET_ORDER_LIST_PAGE = '[DASHBOARD] SET_ORDER_LIST_PAGE';
export const SET_ORDER_LIST_FILTER = '[DASHBOARD] SET_ORDER_LIST_FILTER';
export const SET_ORDER_LIST_SEARCH = '[DASHBOARD] SET_ORDER_LIST_SEARCH';
export const GET_FILES_FOR_DOWNLOAD = '[DASHBOARD] GET_FILES_FOR_DOWNLOAD';
export const CLEAR_FILES_FOR_DOWNLOAD = '[DASHBOARD] CLEAR_FILES_FOR_DOWNLOAD';
export const START_EDITING_ORDER = '[DASHBOARD] START_EDITING_ORDER';
export const START_CLONING_ORDER = '[DASHBOARD] START_CLONING_ORDER';
export const CLEAR_ORDERFORM = '[DASHBOARD] CLEAR_ORDERFORM';
export const SET_ORDERLIST_VIEW = '[DASHBOARD] SET_ORDERLIST_VIEW';
export const HIDE_ORDER = '[DASHBOARD] HIDE_ORDER';
export const UNHIDE_ORDER = '[DASHBOARD] UNHIDE_ORDER';
export const TOGGLE_HIDDEN_ORDERS = '[DASHBOARD] TOGGLE_HIDDEN_ORDERS';
export const SET_DATE_FILTER = '[DASHBOARD] SET_DATE_FILTER';
export const CLEAR_ALL_FILTERS = '[DASHBOARD] CLEAR_ALL_FILTERS';
export const TOGGLE_FETCHITN_STATUS = '[DASHBOARD] TOGGLE_FETCHITN_STATUS';

// TRANSACTIONS
export const FETCH_TRANSACTION_LIST = '[TRANSACTION] FETCH_TRANSACTION_LIST';
export const SET_TRANSACTION_LIST_PAGE = '[TRANSACTION] SET_TRANSACTION_LIST_PAGE';
export const SET_TRANSACTION_LIST_FILTER = '[TRANSACTION] SET_TRANSACTION_LIST_FILTER';

// ORDERINFO
export const FETCH_ORDER_INFO = '[ORDERINFO] FETCH_ORDER_INFO';
export const FETCH_ORDER_INFO_ERROR = '[ORDERINFO] FETCH_ORDER_INFO_ERROR';
export const CLEAR_ORDER_INFO = '[ORDERINFO] CLEAR_ORDER_INFO';

// FILES
export const FETCH_CUSTOMERS_FILES = '[FILES] FETCH_CUSTOMERS_FILES';
export const FETCH_WRITERS_FILES = '[FILES] FETCH_WRITERS_FILES';
export const CLEAR_FILES_INFO = '[FILES] CLEAR_FILES_INFO';
export const CLEAR_DROPZONE_FILES = '[FILES] CLEAR_DROPZONE_FILES';
export const ADD_FILE = '[FILES] ADD_FILE';
export const REMOVE_FILE = '[FILES] REMOVE_FILE';
export const CALCULATE_FILES_COUNT = '[FILES] CALCULATE_FILES_COUNT';

// LOYALTY
export const FETCH_DISCOUNT_RULES = '[LOYALTY] FETCH_DISCOUNT_RULES';
export const FETCH_DISCOUNT_LIST = '[LOYALTY] FETCH_DISCOUNT_LIST';
export const STOP_LOYALTY_FETCHING = '[LOYALTY] STOP_LOYALTY_FETCHING';
export const SET_CURRENT_RULE = '[LOYALTY] SET_CURRENT_RULE';
export const CREATE_DISCOUNT = '[LOYALTY] CREATE_DISCOUNT';
export const FETCH_REFERRAL_LINK = '[LOYALTY] FETCH_REFERRAL_LINK';
export const GET_READY_REFERRALS = '[LOYALTY] GET_READY_REFERRALS';
export const FETCH_REFERRALS = '[LOYALTY] FETCH_REFERRALS';
export const REF_DISCOUNT_GENERATION_SUCCESS = '[LOYALTY] REF_DISCOUNT_GENERATION_SUCCESS';
export const SET_REFERRAL_FILTER = '[LOYALTY] SET_REFERRAL_FILTER';

//REVISIONS
export const FETCH_REVISION_CATEGORIES = '[REVISIONS] FETCH_REVISION_CATEGORIES';
export const FETCH_REVISION_LEVELS = '[REVISIONS] FETCH_REVISION_LEVELS';
export const FETCH_REVISION_LIST = '[REVISIONS] FETCH_REVISION_LIST';
export const FETCH_REVISION_BY_ID = '[REVISIONS] FETCH_REVISION_BY_ID';
export const FETCH_REVISION_CUSTOMER_FILES = '[REVISIONS] FETCH_REVISION_CUSTOMER_FILES';
export const FETCH_REVISION_WRITER_FILES = '[REVISIONS] FETCH_REVISION_WRITER_FILES';
export const CLEAR_REVISIONS = '[REVISIONS] CLEAR_REVISIONS';
export const CLEAR_ACTIVE_REVISION = '[REVISIONS] CLEAR_ACTIVE_REVISION';
export const ADD_REVISION_FILE = '[REVISIONS] ADD_REVISION_FILE';
export const ADD_REVISION_FILE_ERROR = '[REVISIONS] ADD_REVISION_FILE_ERROR';
export const CLEAR_REVISION_FILE = '[REVISIONS] CLEAR_REVISION_FILE';
export const SET_REVISION_LEVEL = '[REVISIONS] SET_REVISION_LEVEL';
export const SET_REVISION_CATEGORY = '[REVISIONS] SET_REVISION_CATEGORY';
export const SET_REVISION_DESCRIPTION = '[REVISIONS] SET_REVISION_DESCRIPTION';
export const CHECK_REVISION_POLICY = '[REVISIONS] CHECK_REVISION_POLICY';
export const CREATE_REVISION = '[REVISIONS] CREATE_REVISION';

// FEEDBACK
export const SET_FEEDBACK_RATING = '[FEEDBACK] SET_FEEDBACK_RATING';
export const SET_FEEDBACK_COMMENT = '[FEEDBACK] SET_FEEDBACK_COMMENT';
export const SET_FEEDBACK_INFO = '[FEEDBACK] SET_FEEDBACK_INFO';
export const CLEAR_FEEDBACK_INFO = '[FEEDBACK] CLEAR_FEEDBACK_INFO';

// MESSAGES
export const FETCH_MESSAGES = '[MESSAGES] FETCH_MESSAGES';
export const INITIAL_FETCH = '[MESSAGES] INITIAL_FETCH';
export const FETCH_MESSAGES_STATUS = '[MESSAGES] FETCH_MESSAGES_STATUS';
export const SET_WRITER_MESSAGES_STATUS = '[MESSAGES] SET_WRITER_MESSAGES_STATUS';
export const SET_SUPPORT_MESSAGES_STATUS = '[MESSAGES] SET_SUPPORT_MESSAGES_STATUS';
export const CLEAR_MESSAGES_INFO = '[MESSAGES] CLEAR_MESSAGES_INFO';
export const SET_MESSENGER_VIEW = '[MESSAGES] SET_MESSENGER_VIEW';
export const FETCH_MESSAGES_FILES = '[MESSAGES] FETCH_MESSAGES_FILES';
export const FETCH_UNREAD_ORDERS = '[MESSAGES] FETCH_UNREAD_ORDERS';
export const SET_MESSAGE_VALUE = '[MESSAGES] SET_MESSAGE_VALUE';
export const CLEAR_MESSAGE_VALUE = '[MESSAGES] CLEAR_MESSAGE_VALUE';
export const SEND_MESSAGE = '[MESSAGES] SEND_MESSAGE';
export const GET_MESSAGES_COUNT = '[MESSAGES] GET_MESSAGES_COUNT';

// ORDER-MESSENGER
export const TOGGLE_CURRENT_CHAT = '[ORDER-MESSENGER] TOGGLE_CURRENT_CHAT';
export const SET_CURRENT_CHAT = '[ORDER-MESSENGER] SET_CURRENT_CHAT';
export const SET_ORDER_MESSAGE_VALUE = '[ORDER-MESSENGER] SET_ORDER_MESSAGE_VALUE';
export const PUSH_MESSAGE = '[ORDER-MESSENGER] PUSH_MESSAGE';
export const FETCH_MESSAGE_HISTORY = '[ORDER-MESSENGER] FETCH_MESSAGE_HISTORY';
export const SET_UNREAD_COUNT = '[ORDER-MESSENGER] SET_UNREAD_COUNT';
export const SET_TOTAL_UNREAD_COUNT = '[ORDER-MESSENGER] SET_TOTAL_UNREAD_COUNT';
export const READ_MESSAGES = '[ORDER-MESSENGER] READ_MESSAGES';
export const GO_NEW_LINE = '[ORDER-MESSENGER] GO_NEW_LINE';
export const RESET_CHAT = '[ORDER-MESSENGER] RESET_CHAT';
export const CLEAR_BEFORE_HISTORY_FETCH = '[ORDER-MESSENGER] CLEAR_BEFORE_HISTORY_FETCH';
export const SET_ONLINE_STATUS = '[ORDER-MESSENGER] SET_ONLINE_STATUS';
export const SET_EMOJI = '[ORDER-MESSENGER] SET_EMOJI';

export const UPDATE_CHAT_HISTORY = '[ORDER-MESSENGER-SOCKET] UPDATE_CHAT_HISTORY';
export const UPDATE_CHAT = '[ORDER-MESSENGER-SOCKET] UPDATE_CHAT';
export const APPEND_MESSAGE = '[ORDER-MESSENGER-SOCKET] APPEND_MESSAGE';
export const UPDATE_UNREAD = '[ORDER-MESSENGER-SOCKET] UPDATE_UNREAD';
export const SET_UNREAD_LIST = '[ORDER-MESSENGER-SOCKET] SET_UNREAD_LIST';
export const TOGGLE_TYPE = '[ORDER-MESSENGER-SOCKET] TOGGLE_TYPE';

export const CLEAR_SEND_QUEUE_FILES = '[ORDER-MESSENGER] CLEAR_SEND_QUEUE_FILES';
export const ADD_FILE_TO_SEND_QUEUE = '[ORDER-MESSENGER] ADD_FILE_TO_SEND_QUEUE';
export const CLICK_ON_SEND_QUEUE_FILE_INPUT = '[ORDER-MESSENGER] CLICK_ON_SEND_QUEUE_FILE_INPUT';
export const UPLOAD_SEND_QUEUE_FILE = '[ORDER-MESSENGER] UPLOAD_SEND_QUEUE_FILE';
export const UPLOAD_SEND_QUEUE_FILE_ERROR = '[ORDER-MESSENGER] UPLOAD_SEND_QUEUE_FILE_ERROR';
export const DELETE_SEND_QUEUE_FILE = '[ORDER-MESSENGER] DELETE_SEND_QUEUE_FILE';

// REFERRAL
export const FETCH_REFERRALS_LIST = '[REFERRAL] FETCH_REFERRALS_LIST'
export const FETCH_REFERRER_DETAILS = '[REFERRAL] FETCH_REFERRER_DETAILS'
