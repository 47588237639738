import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PaymentSelect from './PaymentSelect';

import { toggleModal, makePayment } from '../../store/global/globalActions';

/**
 * @component
 * @category Dashboard
 * @subcategory PaymentModal
 * @hideconstructor
 */
function PaymentModal({ paymentSystems, uid, orderId, price, toggleModal, makePayment }) {
    const systems = [
        { id: 'paycore', name: 'PayCore' },
        { id: 'paypal', name: 'PayPal' },
        { id: 'fondy', name: 'Fondy' },
        { id: 'paymentwall', name: 'PaymentWall' }
    ]

    const [activeSystem, setActiveSystem] = useState('paycore');
    const [validList, setSetValidList] = useState([systems[0]]);
    const [isOpened, setIsOpened] = useState(false);

    // Check for orderform url
    const order = window.location.pathname.startsWith('/order') && !window.location.pathname.startsWith('/orderinfo');

    const handleClose = () => {
        if (order) {
            window.location.assign(`/orderinfo/${orderId}/details`);
        }

        toggleModal();
    }

    /** */
    const hanleDropdownToggle = () => {
        setIsOpened(!isOpened);
    }

    /**
     * @param {Event} e
     */
    const handleClickOutside = (e) => {
        if (!e.target.closest('.payment-select__group')) {
            setIsOpened(false);
        }
    }

    /**
     * Select payment system
     * @param {string} id payment system name
     */
    const handleSelect = (id) => {
        setActiveSystem(id);
        setIsOpened(false);
    }

    useEffect(() => {
        const arr = systems.filter(item => paymentSystems.includes(item.id));

        setSetValidList(arr);
        setActiveSystem(paymentSystems[0]);
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="payment">
            <button
                className="modal__close-btn"
                onClick={handleClose}
            >
                <svg width="14" height="14" viewBox="0 0 14 14"><g><path d="M.636 11.95L5.586 7 .636 2.05 2.05.636 7 5.586l4.95-4.95 1.414 1.414L8.414 7l4.95 4.95-1.415 1.414L7 8.414l-4.95 4.95z" /></g></svg>
            </button>
            <h4 className="modal__title">Choose your payment method</h4>

            <PaymentSelect
                isOpened={isOpened}
                value={activeSystem}
                options={validList}
                hanleDropdownToggle={hanleDropdownToggle}
                handleSlect={handleSelect}
            />

            <footer className="modal__footer">
                <button
                    className="modal__pay-btn"
                    onClick={makePayment.bind(null, activeSystem, uid)}
                >
                    Pay ${price?.toFixed(2)}
                </button>
            </footer>
        </div>
    )
}

PaymentModal.propTypes = {
    paymentSystems: PropTypes.array,
    uid: PropTypes.string,
    price: PropTypes.number,
    toggleModal: PropTypes.func,
    makePayment: PropTypes.func
}

const mapDispatchToProps = {
    toggleModal,
    makePayment
}

export default connect(null, mapDispatchToProps)(PaymentModal);
