import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import NewOrderIcon from '../img/NewOrderIcon';
import OrdersIcon from '../img/OrdersIcon';
import TransactionsIcon from '../img/TransactionsIcon';
import SettingsIcon from '../img/SettingsIcon';
import BonusesIcon from '../img/BonusesIcon';
import DetailsIcon from '../img/DetailsIcon';
import FilesIcon from '../img/FilesIcon';
import MessagesIcon from '../img/MessagesIcon';
import RevisionsIcon from '../img/RevisionsIcon';
import DiscountIcon from '../img/DiscountIcon';
import ReferralIcon from '../img/ReferralIcon';
import { BadgeDot } from 'shared/BadgeDot';

const types = {
  NewOrderIcon,
  OrdersIcon,
  TransactionsIcon,
  SettingsIcon,
  BonusesIcon,
  DetailsIcon,
  FilesIcon,
  MessagesIcon,
  RevisionsIcon,
  DiscountIcon,
  ReferralIcon
};

/**
 * @name TabLinks
 * @component
 * @category Dashboard
 * @subcategory Components
 * @hideconstructor
 */
export function TabLinks({ links, baseUrl, orderId }) {
  return (
    <ul className="tablinks__list">
      {links.map((link, index) => {
        const ImageHolder = types[link.icon];
        const url = orderId ? `${baseUrl}/${orderId}` : `${baseUrl}`;

        if (link.disabled) {
          return null;
        } else {
          return (
            <li key={index}>
              <NavLink id={link.id} className="tablinks__link" to={`${url}${link.url}`}>
                <ImageHolder />

                <span className="tablinks__label">
                  <BadgeDot show={link.dot}>
                    {link.name}
                  </BadgeDot>
                </span>

                {link.counter && (
                  <span className={`tablinks__counter ${link.id}`} data-tooltip={link.tooltip}>
                    [<span className="counter-value">{link.counter}</span>]
                  </span>
                )}
              </NavLink>
            </li>
          );
        }
      })}
    </ul>
  );
}

TabLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.any,
      icon: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  orderId: PropTypes.string,
  baseUrl: PropTypes.string,
};

export default TabLinks
