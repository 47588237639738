/**
 * @module LoyaltyActions
 */
import {
    FETCH_DISCOUNT_RULES,
    FETCH_DISCOUNT_LIST,
    STOP_LOYALTY_FETCHING,
    SET_CURRENT_RULE,
    CREATE_DISCOUNT,
    FETCH_REFERRAL_LINK,
    // GET_READY_REFERRALS,
    // FETCH_REFERRALS,
    REF_DISCOUNT_GENERATION_SUCCESS,
    SET_REFERRAL_FILTER
} from "store/actionTypes";

import { getDiscountRulesURL, getDiscountListURL, referralLinkURL, generateRefDiscountURL } from "store/config";
import { notify } from 'helpers/notification';
import http from 'services/http';

/** */
export function stopLoyaltyFetching() {
    return (dispatch) => {
        dispatch({ type: STOP_LOYALTY_FETCHING });
    }
}

/** */
export function fetchDiscountRules() {
  return (dispatch) => http
    .get(getDiscountRulesURL)
    .then((res) => {
      if (res?.rules) {
        const { rules, points } = res;
        dispatch({
          type: FETCH_DISCOUNT_RULES,
          payload: { points, rules },
        });

        rules.forEach((rule, index) => {
          if (points >= rule.from && (points <= rule.to || rule.to === null)) {
            dispatch({
              type: SET_CURRENT_RULE,
              payload: { index, rule },
            });
          }
        });
      }
    })
    .catch(console.error);
}

/** */
export function fetchDiscountList() {
  return (dispatch) =>
    http
      .get(getDiscountListURL)
      .then((res) => {
        if (res.length) {
          res[0].isActive &&
            dispatch({
              type: FETCH_DISCOUNT_LIST,
              payload: res[0].code,
            });
        }
      })
      .catch((err) => console.log(err));
}

/** */
export function createDiscount(e) {
    e.preventDefault();

    return function (dispatch, getState) {
        const { dashboard: { loyalty } } = getState();

        if (loyalty.currentRule.rule.percent > 0) {
            return http
                .post(getDiscountListURL)
                .then(res => {
                    dispatch({
                        type: CREATE_DISCOUNT,
                        payload: res.code
                    });
                })
                .catch(err => console.log(err))

        } else {
            notify.warn("You don't have enough points to generate discount.");
        }
    }

}

/** */
export function fetchReferralLink(resolve) {
    return function (dispatch) {
        return http
            .get(referralLinkURL)
            .then(data => {
                dispatch({
                    type: FETCH_REFERRAL_LINK,
                    payload: data
                })

                resolve();
            })
            .catch(err => console.log(err))
    }
}

// /** */
// export function fetchReferrals(resolve, idStatus) {
//     return function (dispatch) {
//         return http
//             .get(referralsURL(idStatus))
//             .then(data => {
//                 dispatch({
//                     type: FETCH_REFERRALS,
//                     payload: data
//                 })

//                 resolve && resolve();
//             })
//             .catch(err => console.log(err))
//     }
// }

/** */
// export function getReadyDiscountsAmount() {
//   return (dispatch) =>
//     http
//       .get(referralsURL())
//       .then((data) => {
//         if (data.length) {
//           const readyDiscountAmount = data.filter(({ status }) => status.id === 3).length;

//           dispatch({
//             type: GET_READY_REFERRALS,
//             payload: readyDiscountAmount,
//           });
//         }
//       })
//       .catch(console.error);
// }


/** */
export function generateRefDiscount(refId, statusId) {
    return function (dispatch) {
        if (statusId === 3) {
            return http
                .post(generateRefDiscountURL(refId))
                .then(data => {
                    if (data.id) {
                        dispatch({
                            type: REF_DISCOUNT_GENERATION_SUCCESS,
                            payload: data
                        })
                    }
                })
                .catch(({ message, body }) => {
                    if (body) {
                        body.errors?.length
                            ? console.error(body.errors.join(' '))
                            : console.log(body);

                    } else {
                        console.log(message);
                    }
                    notify.error('Problem occurred while generating your discount');
                })
        }
    }
}

/** */
export function setReferralFilter(statusId) {
    return function (dispatch) {

        dispatch({
            type: SET_REFERRAL_FILTER,
            payload: +statusId
        });
    }
}
