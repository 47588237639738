import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HeaderMenu } from "dashboard/components/layout/HeaderMenu";
import { useLocation } from "react-router-dom";
import { APP_VERSION, HOME_URL } from 'const';

export const MainLayout = ({ children }) => {
  const [isFixed, setFixed] = useState(false);
  const { isAuthorized } = useSelector(({ global }) => global);
  const { pathname } = useLocation();

  const handleScroll = () => {
    setFixed(window.scrollY >= 30);
  };

  useEffect(() => {
    if (!HOME_URL) return;
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {HOME_URL && <header className={`site-header ${isFixed ? "fixed" : ""}`}>
        <div className="site-header__container container">
          <a href={HOME_URL} className="logo__wrapper">
            <img className="logo" src={`${HOME_URL}/static/img/logo.svg`} alt="logo" />
          </a>
          <HeaderMenu />
        </div>
      </header>}

      <div dashboard-version={APP_VERSION} className={`page is-${pathname?.replace("/", "")} is-${isAuthorized ? "loggedin" : "guest"}`}>
        <div className="container">{children}</div>
      </div>
    </>
  );
};

export default MainLayout
