/**
 * @module OrderformGlobalActions
 */
 import http from 'services/http';
 import { FETCH_DEFAULT_SETTINGS, FETCH_DEFAULT_SETTINGS_FAILED, CALCULATE_PRICE, CREATE_ORDER, USER_SIGN_UP_SUCCESS, USER_SIGN_IN_SUCCESS, USER_MAIL_EXISTS, SET_TEXTFIELD_VALUE, CLEAR_FILES, ADD_FILE, SECOND_STEP_VALIDATION, THIRD_STEP_VALIDATION } from 'store/orderform/orderformActionTypes';

 import { validateSecondStep, phoneValidation, emailValidation, validateThirdStep, checkConsiderDeadline } from 'orderform/utils/orderformUtils';
 import { calcURL, createOrderURL, fileURL, shouldRender } from 'orderform/utils/config';
 import { settingsURL, signinURL, signupURL, checkmailURL, } from 'store/config';
 import { clearLocalStorage } from 'store/orderform/orderformglobal/restoreAction';
 import { notify } from 'helpers/notification';
 import { achieveGoals } from 'store/metricaActions';
 import { choosePaymentSystem } from 'store/global/globalActions';
 import { getFingerprint, isInViewport } from 'helpers/utils';
 import { tokenService } from 'services/tokenService';
 import { getReferrerId } from 'hooks/useReferralId';

 /** */
 export function fetchDefaultSettings() {
     return async function (dispatch) {
       console.log(`Fetched default settings`)
       return fetch(settingsURL)
         .then(response => response.json())
         .then(data => {
             dispatch({
                 type: FETCH_DEFAULT_SETTINGS,
                 payload: data
             });
         }).catch(err => {
             dispatch({
                 type: FETCH_DEFAULT_SETTINGS_FAILED,
                 payload: err.message
             });
         });
     }
 }

 /** */
 export function handleCalcPrice(dispatch, getState) {
     return ((function () {
         const { global: { siteCategory, isAuthorized }, orderform: { workType, level, pages, spacing, urgency, topWriters, extraqualityCheck, preferredWriters, qualityFeature, onePageSummary, discount } } = getState();

         const workTypeVal = +workType.value;
         const levelVal = level.value;
         const pagesVal = +pages.value || 1;
         const spacingVal = spacing.value;
         const urgencyVal = urgency.value;
         const topWritersVal = topWriters.value === 1 ? false : true;
         const preferredWritersVal = preferredWriters.preferredWriterList;
         const anotherWriterVal = preferredWriters.anotherWriter;
         const extraqualityCheckVal = extraqualityCheck.value === 1 ? false : true;
         const onePageSummaryVal = onePageSummary.value === 1 ? false : true;
         const qualityFeatureVal = qualityFeature.value === 1 ? false : true;
         const discountAmountVal = discount.amount ? discount.amount.toString() : '0';
         const discountPercentVal = discount.percent ? discount.percent.toString() : '0';

         const lvl = shouldRender(siteCategory, getState).levelFieldName;

         const data = {
             idWorkType: workTypeVal,
             [lvl]: levelVal,
             discountAmount: discountAmountVal,
             discountPercent: discountPercentVal,
             pages: pagesVal,
             idSpacing: spacingVal,
             urgency: urgencyVal,
             premiumWriter: topWritersVal,
             preferredWriterList: preferredWritersVal,
             preferredWriterAnother: anotherWriterVal,
             originalityReport: qualityFeatureVal,
             extraQualityCheck: extraqualityCheckVal,
             onePageSummary: onePageSummaryVal
         }

         // Calculate with JWT for authorized user
         if (isAuthorized) {
             return http
                 .put(calcURL(siteCategory), {
                     headers: {
                         'Content-Type': 'application/json'
                     },
                     body: JSON.stringify(data)
                 })
                 .then(data => {
                     const total = document.querySelector('.total');
                     const finalPrice = `$${data.finalPrice ? data.finalPrice.toFixed(2) : '0.00'}`;

                     dispatch({
                         type: CALCULATE_PRICE,
                         payload: data
                     });
                     checkConsiderDeadline(workType.value, pages.value, urgency.value, spacing.value, finalPrice, notify);

                     if (!isInViewport(total)) {
                         notify.success(finalPrice, ' ');
                     }
                 })
                 .catch(console.error);
         }

         // Calculate for unauthorized user
         return fetch(calcURL(siteCategory), {
             method: 'PUT',
             headers: {
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(data)
         })
             .then(response => response.json())
             .then(data => {
                 const total = document.querySelector('.total');
                 const finalPrice = `$${data.finalPrice ? data.finalPrice.toFixed(2) : '0.00'}`;

                 dispatch({
                     type: CALCULATE_PRICE,
                     payload: data
                 });
                 checkConsiderDeadline(workType.value, pages.value, urgency.value, spacing.value, finalPrice, notify);

                 if (!isInViewport(total)) {
                     notify.success(finalPrice, ' ');
                 }
             })
             .catch(console.error);
     })())
 }

 /** */
 export function handleCreateOrder(dispatch, getState, paymentSystem = false) {
     return ((function () {
         const { global: { siteCategory, signUpSuccess }, orderform: { workType, level, paperFormat, paperType, resumeService, subject, pages, sources, spacing, title, paperDetails, credentials, file, urgency, topWriters, extraqualityCheck, preferredWriters, qualityFeature, onePageSummary, discount, price } } = getState();

         const workTypeVal = +workType.value;
         const levelVal = level.value;
         const paperFormatVal = paperFormat.value;
         const paperTypeVal = paperType.value;
         const resumeServiceVal = resumeService.value;
         const subjectVal = subject.value;
         const pagesVal = +pages.value;
         const sourcesVal = +sources.value;
         const spacingVal = spacing.value;
         const titleVal = title.value.replace(/(<([^>]+)>)/ig, "");
         const paperDetailsVal = paperDetails.value.replace(/(<([^>]+)>)/ig, "");
         const credentialsVal = credentials.value.replace(/(<([^>]+)>)/ig, "");
         const files = file.fileObjects;
         const urgencyVal = urgency.value;
         const topWritersVal = topWriters.value === 1 ? false : true;
         const preferredWritersVal = preferredWriters.preferredWriterList;
         const anotherWriterVal = preferredWriters.anotherWriter;
         const extraqualityCheckVal = extraqualityCheck.value === 1 ? false : true;
         const onePageSummaryVal = onePageSummary.value === 1 ? false : true;
         const qualityFeatureVal = qualityFeature.value === 1 ? false : true;
         const discountCodeVal = discount.code.replace(/(<([^>]+)>)/ig, "");

         const lvl = shouldRender(siteCategory, getState).levelFieldName;

         const data = {
             idWorkType: workTypeVal,
             [lvl]: levelVal,
             paperFormat: paperFormatVal,
             paperType: paperTypeVal,
             resumeService: resumeServiceVal,
             subject: subjectVal,
             discountCode: discountCodeVal,
             pages: pagesVal,
             sources: sourcesVal,
             idSpacing: spacingVal,
             topic: titleVal,
             paperDetails: paperDetailsVal,
             credentials: credentialsVal,
             urgency: urgencyVal,
             premiumWriter: topWritersVal,
             preferredWriterList: preferredWritersVal,
             preferredWriterAnother: anotherWriterVal,
             originalityReport: qualityFeatureVal,
             extraQualityCheck: extraqualityCheckVal,
             onePageSummary: onePageSummaryVal,
             premiumSupport: false
         }

         notify.wait('Your order is now being created');
         dispatch(achieveGoals('click_create_new_order'));

         //add fingerprint hash
         return getFingerprint().then((hash) => {
             // Temporarily disabled
             // data.hashDevice = hash;
             return http
                 .post(createOrderURL(siteCategory), {
                     headers: {
                         'Content-Type': 'application/json'
                     },
                     body: JSON.stringify(data)
                 })
                 .then(data => {
                     if (files.length) {
                         const formData = new FormData();

                         files.forEach(file => {
                             formData.append('file', file.fileObj);
                         });

                         // Sending files
                         return http
                             .post(fileURL(data.id), { body: formData })
                             .then(() => {
                                 dispatch(achieveGoals('created_new_order'));
                                 clearLocalStorage();
                                 paymentSystem
                                     ? choosePaymentSystem(workType.value, data.uid, price.finalPrice, data.id)(dispatch, getState)
                                     : window.location.href = `/orderinfo/${data.id}/details`;
                             })
                             .catch(err => {
                                 console.log(err);
                                 dispatch({ type: CREATE_ORDER });
                             });

                     } else {
                         dispatch(achieveGoals('created_new_order'));
                         clearLocalStorage();
                         paymentSystem
                             ? choosePaymentSystem(workType.value, data.uid, price.finalPrice, data.id)(dispatch, getState)
                             : window.location.assign(`/orderinfo/${data.id}/details`);
                     }
                 })
                 .catch(({ message, body }) => {
                     if (body) {
                         console.error(body);

                         if (body.errors.discountCode && body.errors.discountCode.length) {
                             notify.error(body.errors.discountCode[0]);

                         } else if (body.errors.topic && body.errors.topic.length) {
                             notify.error('Please provide valid Title');
                             document.querySelector('#title') && document.querySelector('#title').focus();

                         } else if (body.errors.paperDetails && body.errors.paperDetails.length) {
                             notify.error('Paper Details must be empty or contain at least 10 characters');
                             document.querySelector('#paperDetails') && document.querySelector('#paperDetails').focus();

                         } else {
                             notify.error('Something went wrong');
                         }

                         if (signUpSuccess) {
                             clearLocalStorage();
                             window.location.assign('/dashboard');
                         }

                     } else {
                         console.log(message);
                     }

                     dispatch({ type: CREATE_ORDER });
                 })

         });
     })())
 }

 /** */
 export function handleLogin(dispatch, getState, paymentSystem = false) {
     return ((function () {
         const { phone, email, password, name, calls, userType } = getState().orderform;

         const preparedPhoneVal = phone.value.replace(/\D/gi, '').split('').filter(char => char !== '').join('');
         const phoneVal = phone.dialCode + preparedPhoneVal;
         const emailVal = email.value;
         const passwordVal = password.value;
         const nameVal = name.value;
         const callsVal = calls.checked;

         const referrerId = getReferrerId();

         const dataSignUp = {
           phone: phoneVal,
           email: emailVal,
           password: passwordVal,
           firstName: nameVal,
           isEnabledNightCall: callsVal,
           lastName: '',
           referrerId
         }

         console.log(`handleLogin > referrerId`, dataSignUp);

         const dataSignIn = {
             email: emailVal,
             password: passwordVal
         }

         function signIn(data) {
             exportFunctions.handleThirdStepValidation()(dispatch, getState);

             return fetch(signinURL, {
                 method: 'PUT',
                 headers: {
                     'Content-Type': 'application/json'
                 },
                 body: JSON.stringify(data)
             })
                 .then(response => response.json()).then(data => {
                     if (data.message) {
                         dispatch({ type: CREATE_ORDER });
                         notify.error(data.message);
                         return;
                     }

                     http.setAccessToken(data.accessToken);
                     tokenService.setTokens(data);

                     dispatch({
                         type: USER_SIGN_IN_SUCCESS,
                     });

                     exportFunctions.handleCreateOrder(dispatch, getState, paymentSystem);
                 })
                 .catch(console.error);
         }

         function signUp(data) {
             return fetch(signupURL, {
                 method: 'POST',
                 headers: {
                     'Content-Type': 'application/json'
                 },
                 body: JSON.stringify(data)
             })
                 .then(response => response.json()).then(data => {
                     if (data.errors) {
                         console.error(data);
                         dispatch({ type: CREATE_ORDER });

                         if (data.errors.firstName && data.errors.firstName.length) {
                             notify.error('Please provide valid Name');
                             document.querySelector('#name') && document.querySelector('#name').focus();

                         } else if (data.errors.email && data.errors.email.length) {
                             notify.error('Please provide valid Email');
                             document.querySelector('#email') && document.querySelector('#email').focus();

                         } else {
                             notify.error('Something went wrong');
                         }

                         return;
                     }

                     http.setAccessToken(data.accessToken);
                     tokenService.setTokens(data);

                     dispatch({
                         type: USER_SIGN_UP_SUCCESS,
                         payload: data
                     });

                     notify.success('You have successfully signed up');
                     dispatch(achieveGoals('pages_click_to_Signup'));
                     exportFunctions.handleCreateOrder(dispatch, getState, paymentSystem);

                 })
                 .catch(console.error);
         }

         // Check email for existing
         function checkMail() {
             return fetch(checkmailURL, {
                 method: 'POST',
                 headers: {
                     'Content-Type': 'application/json'
                 },
                 body: JSON.stringify({ email: emailVal })
             })
                 .then(response => response.json()).then(data => {
                     if (data.status === 'fail') {
                         //add fingerprint hash
                         return getFingerprint().then((hash) => {
                             dataSignUp.hashDevice = hash;
                             return signUp(dataSignUp)
                         })

                     } else {
                         dispatch({
                             type: USER_MAIL_EXISTS
                         });
                         dispatch({ type: CREATE_ORDER });

                         validateThirdStep(THIRD_STEP_VALIDATION, dispatch, getState);

                         notify.info('This email is already registered. Maybe you are already a member.');
                         document.querySelector('#password').focus();
                     }
                 })
                 .catch(console.error);
         }

         if (userType.value === '22') {
             //add fingerprint hash
             return getFingerprint().then((hash) => {
                 dataSignIn.hashDevice = hash;
                 return signIn(dataSignIn);
             })

         } else {
             return checkMail();
         }

     })())
 }

 /** */
 export function calcPrice() {
     return function (dispatch, getState) {
         handleCalcPrice(dispatch, getState);
     }
 }

 /** */
 export function createOrder(e) {
     e.preventDefault();

     return function (dispatch, getState) {
         dispatch({ type: CREATE_ORDER });
         exportFunctions.handleCreateOrder(dispatch, getState);
     }
 }

 /** */
 export function createOrderWithLogin(e) {
     e.preventDefault();

     return function (dispatch, getState) {
         dispatch({ type: CREATE_ORDER });
         exportFunctions.handleLogin(dispatch, getState);
     }
 }

 /** */
 export function expressCheckout(e) {
     e.preventDefault();

     return function (dispatch, getState) {
         const { global: { isAuthorized } } = getState();

         dispatch({ type: CREATE_ORDER });

         if (isAuthorized) {
             exportFunctions.handleCreateOrder(dispatch, getState, true);

         } else {
             exportFunctions.handleLogin(dispatch, getState, true);
         }
     }
 }

 /** */
 export function handleFirstStepValidation(e) {
     e && e.preventDefault();

     return function () {
         document.querySelector('#subject').click();
         notify.info('Choose the required "Subject"');
     }
 }

 /** */
 export function handleSecondStepValidation(e) {
     e && e.preventDefault();

     return function (dispatch, getState) {
         const { global: { siteCategory }, orderform: { workType, title, paperDetails, file } } = getState();

         const workTypeValue = workType.value;
         const titleValue = title.value.trim();
         const paperDetailsValue = paperDetails.value.trim();

         // Title validation
         if (titleValue.length < 5) {
             if (siteCategory === 3) {
                 dispatch({
                     type: SET_TEXTFIELD_VALUE + '_title',
                     payload: ''
                 });

             } else {
                 dispatch({
                     type: SET_TEXTFIELD_VALUE + '_title',
                     payload: 'Writer’s Choice'
                 });

                 document.querySelector('#title').focus();
                 notify.warn('We will leave the "Title" choice to writer');
             }

             return;
         }

         if (workTypeValue === '2') {
             if (!file.fileObjects.length) {
                 notify.warn('Please choose a file');

                 // Init add file system modal
                 dispatch({
                     type: CLEAR_FILES
                 })

                 dispatch({
                     type: ADD_FILE,
                     payload: {
                         id: `id_${Date.now()}`
                     }
                 });
             }

         } else {
             if (paperDetailsValue.length < 10 && !file.fileObjects.length) {
                 document.querySelector('#paperDetails').focus();
                 notify.warn('Fill out either "Paper Details" or "File(s)" field');
             }
         }

         validateSecondStep(SECOND_STEP_VALIDATION, dispatch, getState);
     }
 }

 /** */
 export function handleThirdStepValidation(e) {
     e && e.preventDefault();

     return function (dispatch, getState) {
         const { orderform: { userType, phone, email, password, terms } } = getState();

         if (userType.value === '11') {

             if (!phoneValidation(phone.value)) {
                 document.querySelector('#phone').focus();
                 notify.info('You may have entered invalid phone number. Kindly consider revising.');
                 return;
             }

             if (!emailValidation(email.value)) {
                 document.querySelector('#email').focus();
                 notify.info('Invalid email address.');
                 return;
             }

             if (!terms.checked) {
                 document.querySelector('#terms').focus();
                 notify.info('Please accept the Terms of Use, Privacy Cookies Policy');
             }

         } else {

             if (!emailValidation(email.value)) {
                 document.querySelector('#email').focus();
                 notify.info('Invalid email address.');
                 return;
             }

             if (!password.value) {
                 document.querySelector('#password').focus();
                 notify.info('The "Password" field is mandatory.');
             }
         }
     }
 }

 /** */
 export function handleFullOrderValidation(firstStepValid, secondStepValid, thirdStepValid, e) {
     e.preventDefault();

     return function (dispatch, getState) {

         if (!firstStepValid) {
             handleFirstStepValidation()();
             return;
         }

         if (!secondStepValid) {
             handleSecondStepValidation()(dispatch, getState);
             return;
         }

         if (!thirdStepValid) {
             handleThirdStepValidation()(dispatch, getState);
         }
     };
 }

 /** */
 export function keyboardInterceptor(e) {
     return function (dispatch, getState) {
         const { orderform: { discount: { isApplyed } } } = getState();
         const inFocus = document.activeElement;

         switch (true) {
             case ((e.key === 'Enter') && !(e.target.id === 'paperDetails')):
                 e.preventDefault();

                 if (inFocus.id === 'discount' && !isApplyed) {
                     document.querySelector('.summary__discount-btn').click();

                 } else {
                     document.querySelector('#proceed-btn').click();
                 }

                 break;

             default:
                 break;
         }
     }
 }

 // This solution is specially for testing
 const exportFunctions = {
     fetchDefaultSettings,
     handleCalcPrice,
     handleCreateOrder,
     calcPrice,
     createOrder,
     handleLogin,
     createOrderWithLogin,
     expressCheckout,
     handleFirstStepValidation,
     handleSecondStepValidation,
     handleThirdStepValidation,
     handleFullOrderValidation,
     keyboardInterceptor
 }

 export default exportFunctions;
