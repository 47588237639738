/**
 * @module GlobalActions
 */
import {
    TOGGLE_MODAL,
    LOCK,
    UNLOCK,
    CLEAR_ORDERFORM,
    SET_VIEW,
    SET_VIEW_ON_RESIZE,
    GO_TO_DASHBOARD,
    SET_PENDING_STATUS
} from "store/actionTypes";

import { getPaymentSystems, getFingerprint } from 'helpers/utils';
import { paymentURL } from "store/config";
import { clearLocalStorage } from "store/orderform/orderformglobal/restoreAction";
import { notify } from 'helpers/notification';

/** */
export function toggleModal() {
    return function (dispatch) {
        dispatch({ type: TOGGLE_MODAL });
    }
}

/** */
export function openModal(Component, modalInfo = {}) {
    return function (dispatch) {
      console.log(`modalInfo`, modalInfo)
        dispatch({
            type: TOGGLE_MODAL,
            payload: { Component, modalInfo }
        });
    }
}

/** */
export function keyboardInterceptor(e) { // TODO
    return function (dispatch, getState) {

    }
}

/** */
export function forgotPassword(e) {
    e.preventDefault();

    return function (dispatch) {
        openModal('ForgotPassModal', { blockClass: 'centered' })(dispatch);
    }
}

/** */
export function lock() {
    return function (dispatch) {
        dispatch({ type: LOCK });
    }
}

/** */
export function unlock() {
    return function (dispatch) {
        dispatch({ type: UNLOCK });
    }
}

/** */
export function setView(e) {
    return function (dispatch) {
        dispatch({
            type: SET_VIEW,
            payload: e.target.id
        });
    }
}

/** */
export function setViewOnResize() {
    return function (dispatch) {

        if (window.innerWidth < 992) {
            dispatch({
                type: SET_VIEW_ON_RESIZE,
                payload: true
            });
        }
    }
}

/** */
export function setPendingStatus(label, orderId, status = false) {
    return function (dispatch) {
        dispatch({
            type: SET_PENDING_STATUS + label,
            payload: { orderId, status }
        })
    }
}

/** */
export function choosePaymentSystem(workType, uid, price, orderId) {
    return function (dispatch, getState) {
        const { global: { siteLanguage, siteCategory, paymentRules, multipayment } } = getState();
        const paymentSystems = getPaymentSystems(siteLanguage, siteCategory, workType, paymentRules);

        if (paymentSystems.length > 1 && multipayment) {
            openModal('PaymentModal', { paymentSystems, uid, orderId, price, blockClass: 'centered' })(dispatch);

        } else {
            makePayment(paymentSystems[0], uid)(dispatch)
        }
    }
}

/** */
export function makePayment(paymentSystem, uid) {
    return function (dispatch) {
        //add fingerprint hash
        return getFingerprint().then((hash) => {
            notify.wait('Please wait for redirect to payment system...');

            // ----- For old endpoints ---
            window.location.assign(`${paymentURL[paymentSystem]}/${uid}?hashDevice=${hash}`);
            // --------------------------
        })
    }
}

/** */
export function clearOrderform() {
    return function (dispatch, getState) {
        const { global } = getState();
        const preparedState = { ...global.predefaultState };
        preparedState.urgency = global.siteCategory === 3 ? global.predefaultState.resumeUrgency : global.predefaultState.urgency;

        clearLocalStorage();

        dispatch({
            type: CLEAR_ORDERFORM,
            payload: preparedState
        });
    }
}

/** */
export function goToDashboard() {
    return function (dispatch) {
        dispatch({ type: GO_TO_DASHBOARD });
    }
}
